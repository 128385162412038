import React from 'react'
import { IonSpinner } from '@ionic/react';

import { connectData } from "../redux";

const Loader = props => {
    return (
      props.data.isLoading || props.showSpinner
      ? <div className="pb-loader">
          <IonSpinner ></IonSpinner>
      </div>
      : null
    )
  }
  

  
export default connectData()(Loader);