import React from 'react'
// import { SafeAreaView, Platform, View } from 'react-native';
// import EStyleSheet from 'react-native-extended-stylesheet';

const PBSafeView = props => {
    return (
        // Platform.OS === 'android' 
        // ?  
        <div 
        // style={[styles.flex,props.style]}
        >
            {props.children}
        </div>
        // : <div style={[styles.flex,props.style]}>
        //     <div style={{flex: 1}}>
        //         {props.children}
        //     </div>
        // </div>
    );
}

const styles = {
    flex: {
        flex: 1, 
    }
}

export default PBSafeView;