import React, { Component } from 'react';

import PropTypes from 'prop-types';

export default class KuposCheckbox extends Component {
	static propTypes = {
		checked: PropTypes.bool,
		onChange: PropTypes.func,
		label: PropTypes.string,
		circular: PropTypes.bool,
		t: PropTypes.func,
	};

	render() {
		return (
			<div className="common-kupos-checkbox">
				<label onClick={this.props.onChange}>
					<div className={'kupos-checkbox '}>
						{/* <input type="checkbox" /> */}
						{this.props.circular ? (
							this.props.checked ? (
								<div className="icon-selection-circle-selected"></div>
							) : (
								<div className="icon-selection-circle-not-selected"></div>
							)
						) : this.props.checked ? (
							<div className="icon-check-square-pink"></div>
						) : (
							<div className="icon-check-square-pink-line"></div>
						)}
					</div>
					{this.props.img && (
						<div>
							<img
								src={this.props.img}
								style={{
									display: 'inline-block',
									height: '28px',
								}}
								// height="20px"
								alt=""
							/>
						</div>
					)}
					<span className="checkbox-label">
						{this.props.t
							? this.props.t('PROFILE.' + this.props.label)
							: this.props.label}
					</span>
				</label>
			</div>
		);
	}
}
