import React, { useEffect, useState } from "react";
// import { View, StyleSheet, Linking, Platform } from 'react-native';
import { dimensions } from "../theme";
import PBModal from "./PBModal";
import PBTouchable from "./PBTouchable";
import PBText from "./PBText";
import { connectData } from "../redux";
// import Image from 'react-native-scalable-image';

const PBContactModal = props => {
  // const PBContactModal = ({metaData,showContactModal,onBackdropPress}) => {
  // console.log('metaData',metaData)
 const renderContactRow = (label, time) => {
    return (
      <div className="contact-row flex-row">
        <div className="flex1">
          <PBText fontSize={"font14"}>{label}</PBText>
        </div>
        <PBText fontSize={"font14"}>{time}</PBText>
      </div>
    );
  };


  useEffect(() => {
    if (props.data.showContactModal)
    props.showHideContactModal(false);
  }, [])

  const renderContactItem = (label, icon, item) => {
    if (item == 1) {
      return (
        <div onClick={() => props.showHideContactModal(false)}>
          <a
            className="contact-modal-whatsapp flex-center-column"
            href={
              "https://api.whatsapp.com/send?phone=" +
              props.data.metaData.appPhoneNumber
            }
          >
            <img className="display-flex" src={icon} />
            <PBText fontSize={"font14"} fontColor={"black-font"}>
              {label}
            </PBText>
          </a>
        </div>
      );
    } else if (item == 2) {
      return (
        <div onClick={() => props.showHideContactModal(false)}>
          <a
            className="contact-modal-phone flex-center-column"
            href={"tel:" + props.data.metaData.appPhoneNumber}
          >
            <img className="display-flex" src={icon} />
            <PBText fontSize={"font14"} fontColor={"black-font"}>
              {label}
            </PBText>
          </a>
        </div>
      );
    } else {
      return (
        <div onClick={() => props.showHideContactModal(false)}>
          <a
            className="contact-modal-email flex-center-column"
            href={"mailto:" + props.data.metaData.admin_email}
          >
            <img className="display-flex" src={icon} />
            <PBText fontSize={"font14"} fontColor={"black-font"}>
              {label}
            </PBText>
          </a>
        </div>
      );
    }
  };

 const contactIconPressed = (i) => {
    // if(i == 1){
    //     Linking.openURL('whatsapp://send?text=hi&phone='+metaData.appPhoneNumber)
    // }else if(i == 2){
    //     let phoneNumber = ''
    //         phoneNumber = 'telprompt:${'+metaData.appPhoneNumber+'}';
    //     Linking.openURL(phoneNumber);
    // }else if(i == 3){
    //     Linking.openURL('mailto:'+metaData.admin_email)
    // }
  };


    const { data, showHideContactModal } = props;
    return data.showContactModal ? (
      <div className="contact-modal">
        <div className="kupos-card">
          <div className="contact-modal-padding">
            <div className="contact-modal-title">
              <PBText
                textAlign={"text-center"}
                fontColor={"dark-font"}
                fontStyle={"bold-font"}
                fontSize={"font15"}
              >
                ¿Necesitas ayuda?
              </PBText>
            </div>
            <div className="contact-modal-subtitle">
              <PBText fontSize={"font14"} textAlign={"text-center"}>
                Contáctanos en nuestros canales de atención.
              </PBText>
            </div>
            <div>
              {renderContactRow("Lunes a Viernes", "9AM - 23PM")}
              {renderContactRow("Sábados y Domingos", "9AM - 19PM")}
              {renderContactRow("Feriados", "9AM - 19PM")}
            </div>
            <div className="flex-row contact-icons">
              {/* {renderContactItem(
                "WhatsApp",
                "/assets/carpool/whatsapp-circle.png",
                "1"
                // () => contactIconPressed(1)
              )} */}
              {renderContactItem(
                "Teléfono",
                "/assets/carpool/call-circle.png",
                "2"
                // () => this.contactIconPressed(2)
              )}
              {renderContactItem(
                "Correo",
                "/assets/carpool/email-circle.png",
                "3"
                // () => this.contactIconPressed(3)
              )}
            </div>
          </div>
        </div>
        <div
          className="contact-modal-backdrop"
          onClick={() => showHideContactModal(false)}
        ></div>
      </div>
    ) : null;

}

export default connectData()(PBContactModal);
