import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./css/global.css";
import "./theme/variables.css";

import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { Loader, PBContactModal } from "./components";
import { Provider } from "./redux";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { AppPage } from "./declarations";
import { CommonService } from "./services";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "./components/Menu";

const HomeScreen = React.lazy(() => import("./screens/HomeScreen/HomeScreen"));
// const MainHomeScreen = React.lazy(() => import("./screens/MainHomeScreen/MainHomeScreen"));

//cuponera screen
const CuponeraHome = React.lazy(
  () => import("./screens/Cuponera/HomeScreen/HomeScreen"),
);
const CuponeraSeatsSelection = React.lazy(
  () => import("./screens/Cuponera/SeatsSelectionScreen/index"),
);
const CuponeraPassengerDetails = React.lazy(
  () => import("./screens/Cuponera/PassengerScreen/index"),
);
const CuponeraPaymentDetails = React.lazy(
  () => import("./screens/Cuponera/PaymentScreen/index"),
);
const ThankyouScreenCuponera = React.lazy(
  () => import("./screens/Cuponera/ThankyouScreen/index"),
);
const CuponeraMyAccountScreen = React.lazy(
  () => import("./screens/Cuponera/Account/MyCuponsScreen/MyCuponsScreen"),
);

//cuponera screens ended

const ServiceListScreen = React.lazy(
  () => import("./screens/ServiceListScreen/ServiceListScreen"),
);
const ServiceListScreenV2 = React.lazy(
  () => import("./screens/ServiceListScreen/ServiceListScreenV2"),
);
const ServiceDetailsScreen = React.lazy(
  () => import("./screens/ServiceDetailsScreen/ServiceDetailsScreen"),
);
const ServiceDetailsScreenV2 = React.lazy(
  () => import("./screens/ServiceDetailsScreen/ServiceDetailsScreenV2"),
);
const PassengerDetailsScreen = React.lazy(
  () => import("./screens/PassengerDetailsScreen/PassengerDetailsScreen"),
);
const PaymentDetailsScreen = React.lazy(
  () => import("./screens/PaymentDetailsScreen/PaymentDetailsScreen"),
);
const ThankyouScreen = React.lazy(
  () => import("./screens/ThankyouScreen/ThankyouScreen"),
);
const LoginScreen = React.lazy(
  () => import("./screens/LoginScreen/LoginScreen"),
);
const RegisterSecreen = React.lazy(
  () => import("./screens/RegisterSecreen/RegisterSecreen"),
);
const ServiceListReturnScreenV2 = React.lazy(
  () => import("./screens/ServiceListReturnScreen/ServiceListReturnScreenV2"),
);
const CancellationScreen = React.lazy(
  () => import("./screens/CancellationScreen/CancellationScreen"),
);
const CancellationDetailsScreen = React.lazy(
  () => import("./screens/CancellationDetailsScreen/CancellationDetailsScreen"),
);
const EditAccountScreen = React.lazy(
  () => import("./screens/EditAccountScreen/EditAccountScreen"),
);
const CancelSuccessScreen = React.lazy(
  () => import("./screens/CancelSuccessScreen/CancelSuccessScreen"),
);

const ChangeTicketScreen = React.lazy(
  () => import("./screens/ChangeTicketScreen/ChangeTicketScreen"),
);

const ConfirmTicketInputScreen = React.lazy(
  () => import("./screens/ConfirmTicket/ConfirmTicketInputScreen"),
);

const TxnDetailsScreen = React.lazy(
  () => import("./screens/TxnDetailsScreen/TxnDetailsScreen"),
);
const TxnHistoryScreen = React.lazy(
  () => import("./screens/TxnHistoryScreen/TxnHistoryScreen"),
);
const CarpoolMyAccountScreen = React.lazy(
  () => import("./screens/CarpoolMyAccountScreen/CarpoolMyAccountScreen"),
);
const CarpoolMyTripsScreen = React.lazy(
  () => import("./screens/CarpoolMyTripsScreen/CarpoolMyTripsScreen"),
);
const NewScanQRScreen = React.lazy(
  () => import("./screens/NewScanQRScreen/NewScanQRScreen"),
);
const NewMyQRScreen = React.lazy(
  () => import("./screens/NewMyQRScreen/NewMyQRScreen"),
);
const ServiceDetailsReturnScreenV2 = React.lazy(
  () =>
    import("./screens/ServiceDetailsReturnScreen/ServiceDetailsReturnScreenV2"),
);
const NewForgotPasswordScreen = React.lazy(
  () => import("./screens/NewForgotPasswordScreen/NewForgotPasswordScreen"),
);
const CarpoolTermsOfUseScreen = React.lazy(
  () => import("./screens/CarpoolTermsOfUseScreen/CarpoolTermsOfUseScreen"),
);
const CarpoolPrivacyPolicy = React.lazy(
  () => import("./screens/CarpoolPrivacyPolicy/CarpoolPrivacyPolicy"),
);
const RechargeWalletScreen = React.lazy(
  () => import("./screens/RechargeWalletScreen/RechargeWalletScreen"),
);
const RechargeMapScreen = React.lazy(
  () => import("./screens/RechargeMapScreen/RechargeMapScreen"),
);
const RechargeStepsScreen = React.lazy(
  () => import("./screens/RechargeStepsScreen/RechargeStepsScreen"),
);
const RechargeTransferScreen = React.lazy(
  () => import("./screens/RechargeTransferScreen/RechargeTransferScreen"),
);
const RefundFormScreen = React.lazy(
  () => import("./screens/RefundFormScreen/RefundFormScreen"),
);
const RegistrationSuccessScreen = React.lazy(
  () => import("./screens/RegistrationSuccessScreen/RegistrationSuccessScreen"),
);
const CarpoolTripDetailsScreen = React.lazy(
  () => import("./screens/CarpoolTripDetailsScreen/CarpoolTripDetailsScreen"),
);
const CarpoolMyTransactionsScreen = React.lazy(
  () =>
    import("./screens/CarpoolMyTransactionsScreen/CarpoolMyTransactionsScreen"),
);
const CarpoolTransactionDetailsScreen = React.lazy(
  () =>
    import(
      "./screens/CarpoolTransactionDetailsScreen/CarpoolTransactionDetailsScreen"
    ),
);
const TransferSuccess = React.lazy(
  () => import("./screens/TransferSuccess/TransferSuccess"),
);
const CarpoolVirtualMoneyScreen = React.lazy(
  () => import("./screens/CarpoolVirtualMoneyScreen/CarpoolVirtualMoneyScreen"),
);
const ModifyProfileScreen = React.lazy(
  () => import("./screens/ModifyProfileScreen/ModifyProfileScreen"),
);
const LoginOptionsScreen = React.lazy(
  () => import("./screens/LoginOptionsScreen/LoginOptionsScreen"),
);
const OtpScreen = React.lazy(() => import("./screens/OtpScreen/OtpScreen"));
const MobileRegisterScreen = React.lazy(
  () => import("./screens/MobileRegisterScreen/MobileRegisterScreen"),
);
const TrainTripDetailsScreen = React.lazy(
  () => import("./screens/TrainTripDetailsScreen/TrainTripDetailsScreen"),
);
const UploadPicScreen = React.lazy(
  () => import("./screens/UploadPicScreen/UploadPicScreen"),
);
const PreferencesScreen = React.lazy(
  () => import("./screens/PreferencesScreen/PreferencesScreen"),
);
const OtpChangePasswordScreen = React.lazy(
  () => import("./screens/OtpChangePasswordScreen/OtpChangePasswordScreen"),
);
const HomeMyCards = React.lazy(
  () => import("./screens/HomeMyCards/HomeMyCards"),
);
const ModifyCardPayments = React.lazy(
  () => import("./screens/ModifyCardPayments/ModifyCardPayments"),
);
const AddCardPayments = React.lazy(
  () => import("./screens/AddCardPayments/AddCardPayments"),
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js") //
    .then(function (reg) {
      console.log("service worker registered");
    })
    .catch(function (err) {
      console.log(err);
    });
}

const appPages2: AppPage[] = [
  {
    id: "2",
    name: "Mi cuenta",
    src: "../assets/icons/menu/icon_avatar_circular.png",
    icon: "icon_avatar_circular",
    // "screen":RECHARGE_WALLET_SCREEN,
    title: "Mi cuenta",
    url: "/my-account",
    newTabLink: false,
  },
  {
    id: "13",
    name: "Mis viajes",
    src: "../assets/icons/menu/icon_my_trips.png",
    icon: "icon_my_trips",
    // "screen":MY_QR_CODE_SCREEN,
    title: "Mis viajes",
    url: "/my-account/my-trips",
    newTabLink: false,
  },
  {
    id: "17",
    name: "Mis movimientos",
    src: "../assets/carpool/sidemenu/movements.png",
    icon: "movements",
    title: "Mis movimientos",
    url: "transactions",
    newTabLink: false,
  },
  // {
  //   id: "30",
  //   name: "Cuponera",
  //   src: "/assets/icons/menu/cuponera_side.png",
  //   icon: "cuponera_side",
  //   // "screen":MY_ACCOUNT_SCREEN,
  //   title: "Cuponera",
  //   url: "/cuponera/home",
  //   newTabLink: false,
  // },
  // {
  //   id: "1",
  //   name: "Mi código QR",
  //   src: "../assets/icons/menu/icon_QR_code.png",
  //   icon: "icon_QR_code",
  //   // "screen":MY_ACCOUNT_SCREEN,
  //   title: "Mi código QR",
  //   url: "/my-account/qr",
  //   newTabLink: false,
  // },
  // {
  //   id: "5",
  //   name: "Mis grupos",
  //   src: "../assets/icons/menu/icon_group.png",
  //   // "screen":RECHARGE_TRANSFER_SCREEN,
  //   title: "Mis grupos",
  //   url: "saldo"
  // },
  {
    id: "12",
    name: "Promociones",
    src: "../assets/icons/menu/icon_promotions.png",
    icon: "icon_promotions",
    // "screen":VIRTUAL_MONEY_SCREEN,
    title: "Promociones",
    url: "promotions",
    newTabLink: false,
  },
  {
    id: "18",
    name: "Recargar PullmanPay",
    src: "../assets/icons/menu/icon_QR_code.png",
    icon: "icon_pullman_pay",
    // "screen":MY_ACCOUNT_SCREEN,
    title: "Recargar PullmanPay",
    // url: "/my-account/qr",
    url: "/recharge-wallet",
    newTabLink: false,
  },
  {
    id: "19",
    name: "Compartir saldo",
    src: "../assets/icons/menu/icon_QR_code.png",
    icon: "icon_saldos",
    // "screen":MY_ACCOUNT_SCREEN,
    title: "Compartir saldo",
    // url: "/my-account/qr",
    url: "/recharge-transfer",
    newTabLink: false,
  },
  {
    id: "4",
    name: "Términos de uso",
    src: "../assets/icons/menu/icon_info.png",
    icon: "icon_info",
    // "screen":TXN_HISTORY_SCREEN,
    title: "Términos de uso",
    url: "tnc",
    newTabLink: false,
  },
  {
    id: "20",
    name: "Políticas de privacidad",
    src: "../assets/icons/menu/icon_QR_code.png",
    icon: "icon_privacy_policy",
    // "screen":MY_ACCOUNT_SCREEN,
    title: "Políticas de privacidad",
    url: "/privacy-policy",
    newTabLink: false,
  },

  // {
  //   id: "3",
  //   name: "Preguntas frecuntes",
  //   src: "../assets/icons/menu/icon_discuss_issue.png",
  //   // "screen":MY_TICKETS_SCREEN,
  //   title: "Preguntas frecuntes",
  //   url: "pasajes"
  // },
  {
    id: "9",
    name: "Contáctanos",
    src: "../assets/icons/menu/icon_contact.png",
    icon: "icon_contact",
    // "screen":MY_TICKETS_SCREEN,
    title: "Contáctanos",
    // url: "pasajes",
    url: "/",
    newTabLink: false,
  },
  // {
  //   id: "16",
  //   name: "Asistente virtual",
  //   src: "/assets/icons/menu/whatsapp_icon.png",
  //   icon: "angry",
  //   // "screen":MY_TICKETS_SCREEN,
  //   title: "Asistente virtual",
  //   // url: "pasajes",
  //   url: "https://api.whatsapp.com/send?phone=56966992643",
  //   newTabLink: true,
  // },
];

// const appPages: AppPage[] = [
//   {
//     title: 'Home',
//     url: '/home',
//     icon: home
//   },
//   {
//     title: 'List',
//     url: '/home/list',
//     icon: list
//   }
// ];

class App extends React.Component {
  state: any;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const customEvent = new Event("addToHomeButtonShowEvent");
    window.addEventListener("beforeinstallprompt", (event: any) => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      CommonService.addToHomeButtonInstallPrompt = event;
      window.dispatchEvent(customEvent);
      // Update the install UI to notify the user app can be installed
      // document.querySelector('#install-button').disabled = false;
      // setAddToHomeState(true)
    });
  }

  render() {
    return (
      <Provider>
        <IonApp>
          <PBContactModal />
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Suspense fallback={<Loader />}>
                <Menu appPages={appPages2} />
                <IonRouterOutlet id="main">
                  <Route exact path="/" component={HomeScreen} />
                  <Route exact path="/bus" component={HomeScreen} />

                  <Route
                    exact
                    path="/bus/:origin/:dest/:dateOnward"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/:origin/:dest/:dateOnward/:dateReturn/1"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/:origin/:dest/:dateOnward/:dateReturn/2"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/servicedetails"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/select-seat/:scheduleId/:origin/:dest/:dateOnward"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsReturnScreenV2}
                  />

                  <Route
                    exact
                    path="/bus/passenger-details"
                    component={PassengerDetailsScreen}
                  />

                  <Route
                    exact
                    path="/bus/servicedetails/passenger-list-return-screen"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/bus/payment-details"
                    component={PaymentDetailsScreen}
                  />

                  <Route
                    exact
                    path="/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  <Route
                    exact
                    path="/privacy-policy"
                    component={CarpoolPrivacyPolicy}
                  />

                  {/* -------------------------------- es routes for bus started -------------------------------- */}
                  <Route exact path="/es/pasajes-bus" component={HomeScreen} />

                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/1"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/2"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/servicedetails"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsReturnScreenV2}
                  />

                  <Route
                    exact
                    path="/es/pasajes-bus/passenger-details"
                    component={PassengerDetailsScreen}
                  />

                  <Route
                    exact
                    path="/es/pasajes-bus/servicedetails/passenger-list-return-screen"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/payment-details"
                    component={PaymentDetailsScreen}
                  />

                  <Route
                    exact
                    path="/es/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  {/* -------------------------------- es routes for bus ended -------------------------------- */}

                  {/* -------------------------------- en routes for bus started -------------------------------- */}

                  <Route exact path="/en/bus-tickets" component={HomeScreen} />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/1"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/2"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/servicedetails"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsReturnScreenV2}
                  />

                  <Route
                    exact
                    path="/en/bus-tickets/passenger-details"
                    component={PassengerDetailsScreen}
                  />

                  <Route
                    exact
                    path="/en/bus-tickets/servicedetails/passenger-list-return-screen"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/payment-details"
                    component={PaymentDetailsScreen}
                  />

                  <Route
                    exact
                    path="/en/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  {/* -------------------------------- en routes for bus ended -------------------------------- */}

                  <Route
                    exact
                    path="/transfer/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  <Route
                    exact
                    path="/es/traslados/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip"
                    component={CancellationScreen}
                  />
                  <Route
                    exact
                    path="/bus-change-ticket"
                    component={ChangeTicketScreen}
                  />
                  {/* <Route
                    exact
                    path="/bus-change-ticket-details/:intNumber"
                    component={ChangeTicketDetailsScreen}
                  /> */}
                  <Route
                    exact
                    path="/bus-confirm-ticket"
                    component={ConfirmTicketInputScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip/cancel"
                    // path="/bus-cancel-trip/:pnrNumber"
                    component={CancellationDetailsScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip/refund/:refunType"
                    component={RefundFormScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip/refund-status/:type"
                    component={CancelSuccessScreen}
                  />

                  <Route
                    exact
                    path="/change-ticket-result/:status"
                    component={ThankyouScreen}
                  />

                  <Route
                    exact
                    path="/confirm-ticket-result/:status"
                    component={ThankyouScreen}
                  />
                  {/* <Route exact path="/bus-cancel-trip/:pnrNumber/:refundDetails" component={CancelSuccessScreen} /> */}

                  {/* train routes start */}

                  <Route
                    exact
                    path="/booking/:status"
                    component={ThankyouScreen}
                  />

                  <Route exact path="/login" component={LoginScreen} />
                  <Route
                    exact
                    path="/login-options"
                    component={LoginOptionsScreen}
                  />
                  <Route exact path="/otp" component={OtpScreen} />
                  <Route
                    exact
                    path="/phone-register"
                    component={MobileRegisterScreen}
                  />
                  <Route exact path="/register" component={RegisterSecreen} />
                  <Route exact path="/upload-pic" component={UploadPicScreen} />
                  <Route
                    exact
                    path="/preferences"
                    component={PreferencesScreen}
                  />
                  <Route
                    exact
                    path="/register/status/:type"
                    component={RegistrationSuccessScreen}
                  />
                  <Route
                    exact
                    path="/register/status/:type"
                    component={RegistrationSuccessScreen}
                  />
                  <Route
                    exact
                    path="/forget-password"
                    component={NewForgotPasswordScreen}
                  />
                  <Route
                    exact
                    path="/reset-password-otp"
                    component={OtpChangePasswordScreen}
                  />
                  <Route
                    exact
                    path="/my-account"
                    component={CarpoolMyAccountScreen}
                  />
                  <Route
                    exact
                    path="/profile/edit-profile"
                    component={EditAccountScreen}
                  />
                  <Route
                    exact
                    path="/my-account/txn-details/:item"
                    component={TxnDetailsScreen}
                  />
                  <Route
                    exact
                    path="/my-account/qr"
                    component={NewMyQRScreen}
                  />
                  <Route
                    exact
                    path="/my-account/qr/scan"
                    component={NewScanQRScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-trips"
                    component={CarpoolMyTripsScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-trips/details"
                    // path="/my-account/my-trips/:pnr"
                    component={CarpoolTripDetailsScreen}
                  />
                  <Route
                    exact
                    path="/tnc"
                    component={CarpoolTermsOfUseScreen}
                  />
                  <Route
                    exact
                    path="/recharge-wallet"
                    component={RechargeWalletScreen}
                  />
                  <Route
                    exact
                    path="/recharge-wallet/steps"
                    component={RechargeStepsScreen}
                  />
                  <Route
                    exact
                    path="/recharge-wallet/map"
                    component={RechargeMapScreen}
                  />
                  <Route
                    exact
                    path="/recharge-transfer"
                    component={RechargeTransferScreen}
                  />
                  <Route
                    exact
                    path="/transactions"
                    component={CarpoolMyTransactionsScreen}
                  />
                  <Route
                    exact
                    path="/transactions/details/:tripId"
                    component={CarpoolTransactionDetailsScreen}
                  />
                  <Route
                    exact
                    path="/promotions"
                    component={CarpoolVirtualMoneyScreen}
                  />
                  <Route
                    exact
                    path="/modify-profile"
                    component={ModifyProfileScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-cards"
                    component={HomeMyCards}
                  />

                  <Route
                    exact
                    path="/my-account/modify-card"
                    component={ModifyCardPayments}
                  />

                  {/* { path: '/bip-payment/:status', name: 'Bip payment', component: BipPayment }, */}
                  <Route
                    exact
                    path="/my-account/add-card"
                    component={AddCardPayments}
                  />

                  {/* BIP PAYMENT ROUTES */}

                  <Route
                    exact
                    path="/bip-payment/:status"
                    component={ThankyouScreen}
                  />

                  {/* MY Cards routes  */}
                  <Route
                    exact
                    path="/bank-transfer/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  {/* CUPONERA ROUTES */}
                  <Route exact path="/cuponera/home" component={CuponeraHome} />
                  <Route
                    exact
                    path="/cuponera/payment/:status/:pnr"
                    component={ThankyouScreenCuponera}
                  />

                  <Route
                    exact
                    path="/cuponera/payment/:status"
                    component={ThankyouScreenCuponera}
                  />
                  <Route
                    exact
                    path="/cuponera/seats/:origin/:destination"
                    component={CuponeraSeatsSelection}
                  />
                  <Route
                    exact
                    path="/cuponera/passenger-details"
                    component={CuponeraPassengerDetails}
                  />
                  <Route
                    exact
                    path="/cuponera/payment-details"
                    component={CuponeraPaymentDetails}
                  />
                  <Route
                    exact
                    path="/cuponera/my-cupons"
                    component={CuponeraMyAccountScreen}
                  />
                </IonRouterOutlet>
              </Suspense>
            </IonSplitPane>
          </IonReactRouter>
          <Loader />
        </IonApp>
      </Provider>
    );
  }
}
export default App;
