const AppData = {
  BASE_URL: "https://preprod-pullmanbus.kupos.cl/",
  ONE_SIGNAL_APP_ID: "234fd2db-ea84-4b59-ab4b-28a3ea826474",
  SENDER_ID: "730867902568",
  MAP_API_KEY: "AIzaSyDngZWWwpM0zvheSjUvYu3RlnGnyiSQQ0w",
  // FB_APP_ID: "177184106959636",//
  FB_APP_ID: '1934171803593212',
  CREDITOS_TRAVEL_ID: 304,

};
export default AppData;
