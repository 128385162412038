import React, { PureComponent } from "react";
// import {
//     View,
//     Animated
//   } from 'react-native';

//   import EStyleSheet from 'react-native-extended-stylesheet';

import PBText from "./PBText";
import PBTouchable from "./PBTouchable";
import { CommonService } from "../services";

class PBTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //   menuBg:new Animated.Value(0),
      width: 0,
      height: 0,
      borderRadius: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      selectedTab: 0,
      // border:{
      //   borderRightWidth:2,
      //   borderRightColor:'#aaa',
      //   borderLeftWidth:0,
      //   borderLeftColor:'#aaa'
      // }
    };
  }

  componentDidMount() {}

  onTabClick = (i) => {
    // Animated.timing(                  // Animate over time
    //   this.state.menuBg,            // The animated value to drive
    //   {
    //     toValue: i * (this.state.width/this.props.tabsArray.length),                   // Animate to opacity: 1 (opaque)
    //     duration: 300,              // Make it take a while
    //   }
    // ).start();

    this.setState({
      borderRadius: {
        borderTopLeftRadius: i == 0 ? 8 : 0,
        borderBottomLeftRadius: i == 0 ? 8 : 0,
        borderTopRightRadius: this.props.tabsArray.length == i + 1 ? 8 : 0,
        borderBottomRightRadius: this.props.tabsArray.length == i + 1 ? 8 : 0,
      },
      selectedTab: i,
      // border:{
      //   borderRightWidth:(this.props.tabsArray.length == i+1 ? 0 : 2),
      //   borderRightColor:'#aaa',
      //   borderLeftWidth:(i == 0 ? 0 : 2),
      //   borderLeftColor:'#aaa'
      // }
    });
    this.props.onTabSelect(i);
  };
  // onLayout = (event) => {
  //     const { width, height } = event.nativeEvent.layout;
  //     this.setState({width, height})
  //   };

  render() {
    const { tabsArray } = this.props;
    var dynamicWidth = "calc(100% / " + tabsArray.length + ")";
    var tabPosition =
      "calc(100% / " +
      tabsArray.length +
      " * " +
      (this.props.selectedTab || this.state.selectedTab) +
      ")";
    return (
      <div className="tabs-container">
        <div className="tabs-animation-bg">
          <div
            className="tab-indicator"
            style={{
              width: dynamicWidth,
              left: tabPosition,
              borderRadius: 20,
            }}
          ></div>
        </div>
        <div className="tabs-inner">
          {tabsArray.map((item, i) => (
            <div
              className={
                "tab-item " +
                (this.props.selectedTab
                  ? this.props.selectedTab == i
                    ? "black-text"
                    : "light-text"
                  : this.state.selectedTab == i
                  ? "black-text"
                  : "light-text")
              }
              key={i}
              onClick={() => this.onTabClick(i)}
              style={{ width: dynamicWidth }}
            >
              <PBText
                style={{
                  ...styles.tabsText,
                  color: this.props.textColor || "#fff",
                  fontSize: this.props.fontSize,
                }}
              >
                {this.props.noCapitiliaze
                  ? item
                  : CommonService.capitalize(item)}
              </PBText>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  // tabs ------
  tabs: {
    width: "100%",
    marginVertical: 10,
    backgroundColor: "#ccc",
    borderRadius: 8,
    height: 40,
  },
  tabsInner: {
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  tabsBgAnimationContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  tab: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  tabsText: {
    fontSize: 15,
    color: "#fff",
  },
};

export default PBTabs;
