import React from "react";

import { dimensions, colors } from "../../theme";
import PBText from "../PBText";
import PBTouchable from "../PBTouchable";
import { storageService, CommonService } from "../../services";

const getSeatIcon = (seat) => {
  if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.number &&
    !seat.isSelected &&
    seat.number &&
    (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
      seat.type.toUpperCase().indexOf("CLASICO") > -1)
  ) {
    return "/assets/icons/seats/icon-executive-seat_available.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.isSelected &&
    seat.number &&
    (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
      seat.type.toUpperCase().indexOf("CLASICO") > -1)
  ) {
    return "/assets/icons/seats/icon-executive-seat_selected.png";
  } else if (
    !seat.isGangWay &&
    !seat.isReservable &&
    seat.number &&
    (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
      seat.type.toUpperCase().indexOf("CLASICO") > -1)
  ) {
    return "/assets/icons/seats/icon-executive-seat_occupied.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    !seat.isSelected &&
    seat.type.toUpperCase() == "PREMIUM"
  ) {
    return "/assets/icons/seats/icon-vip-seat_available.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.isSelected &&
    seat.type.toUpperCase() == "PREMIUM"
  ) {
    return "/assets/icons/seats/icon-vip-seat_selected.png";
  } else if (
    !seat.isGangWay &&
    !seat.isReservable &&
    seat.type.toUpperCase() == "PREMIUM"
  ) {
    return "/assets/icons/seats/icon-vip-seat_occupied.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    !seat.isSelected &&
    (seat.type.toUpperCase().indexOf("SALON CAMA") > -1 ||
      seat.type.toUpperCase().indexOf("SALON SIN VENT") > -1)
  ) {
    return "/assets/icons/seats/icon-salon-cama-seat_available.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.isSelected &&
    (seat.type.toUpperCase().indexOf("SALON CAMA") > -1 ||
      seat.type.toUpperCase().indexOf("SALON SIN VENT") > -1)
  ) {
    return "/assets/icons/seats/icon-salon-cama-seat_selected.png";
  } else if (
    !seat.isGangWay &&
    !seat.isReservable &&
    (seat.type.toUpperCase().indexOf("SALON CAMA") > -1 ||
      seat.type.toUpperCase().indexOf("SALON SIN VENT") > -1)
  ) {
    return "/assets/icons/seats/icon-salon-cama-seat_occupied.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    !seat.isSelected &&
    seat.type.toUpperCase().indexOf("PREFERENTE") > -1
  ) {
    return "/assets/icons/seats/icon-vip-seat_available.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.isSelected &&
    seat.type.toUpperCase().indexOf("PREFERENTE") > -1
  ) {
    return "/assets/icons/seats/icon-vip-seat_selected.png";
  } else if (
    !seat.isGangWay &&
    !seat.isReservable &&
    seat.type.toUpperCase().indexOf("PREFERENTE") > -1
  ) {
    return "/assets/icons/seats/icon-vip-seat_occupied.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    !seat.isSelected &&
    seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
  ) {
    return "/assets/icons/seats/icon-semi-bed-seat_available.png";
  } else if (
    !seat.isGangWay &&
    seat.isReservable &&
    seat.isSelected &&
    seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
  ) {
    return "/assets/icons/seats/icon-semi-bed-seat_selected.png";
  } else if (
    !seat.isGangWay &&
    !seat.isReservable &&
    seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
  ) {
    return "/assets/icons/seats/icon-semi-bed-seat_occupied.png";
  } else if (seat.number == "DR_IMG") {
    return "/assets/icons/seats/icon-steering.png";
  }
};

const selectSeat = (
  seat,
  metaData,
  coachLayout,
  selectedSeatsToBook,
  selectedService
) => {
  console.log(seat);

  if (!seat.object.available) {
    return;
  }
  try {
    // CommonService.googleProductClick(
    //   CommonService.convertBusServiceToGoogleProduct(
    //     seat.object,
    //     selectedService,
    //     "train"
    //   )
    // );
    let bookingDetails = {};
    let currentSeat = seat;
    let selectedSeatsNumbers = "";
    selectedSeatsToBook = JSON.parse(
      JSON.stringify(selectedSeatsToBook ? selectedSeatsToBook : [])
    );
    let currentSeatJson =
      coachLayout[currentSeat["object"].row_id][currentSeat["object"].col_id];

    if (
      selectedSeatsToBook.length < metaData.max_seats_allowed ||
      currentSeatJson.isSelected
    ) {
      currentSeatJson.isSelected = !currentSeatJson.isSelected;
      if (currentSeatJson.isSelected) {
        selectedSeatsToBook.push(seat.object);
      } else {
        selectedSeatsToBook.splice(
          selectedSeatsToBook.findIndex((item) => {
            if (item.number === seat.object.number) {
              item.passengerName = "";
              item.age = "";
            }
            return item.number === seat.object.number;
          }),
          1
        );
      }
      let totalBookingAmount = 0;
      let selectedSeats = [];
      let selectedSeatTypes = {};
      for (var i = 0; i < selectedSeatsToBook.length; i++) {
        totalBookingAmount += +selectedSeatsToBook[i].fare;
        selectedSeats.push(
          selectedSeatsToBook[i].floor_no +
            1 +
            "_" +
            selectedSeatsToBook[i].number
        );
        if (!selectedSeatTypes[selectedSeatsToBook[i].type]) {
          selectedSeatTypes[selectedSeatsToBook[i].type] = [];
        }
        selectedSeatTypes[selectedSeatsToBook[i].type].push(
          selectedSeatsToBook[i]
        );
      }
      for (let key in selectedSeatTypes) {
        let seatNumbers = [];
        for (let type of selectedSeatTypes[key]) {
          seatNumbers.push(type.number);
        }
        selectedSeatTypes[key][0]["seatNumbers"] = seatNumbers.join(",");
      }
      selectedSeatsNumbers = selectedSeats.join(",");
      // discountAmount = this.commonService.calculateAmountByPercentage(totalBookingAmount,metaData.discountPercentage);

      let discountAmount = 0;
      let netBookingAmount = totalBookingAmount - discountAmount;

      // -------------------- Fare charges details --------------------
      bookingDetails.totalBookingAmount = totalBookingAmount;
      bookingDetails.netBookingAmount = netBookingAmount;
      bookingDetails.discountAmount = discountAmount;
      bookingDetails.selectedSeatsToBook = selectedSeatsToBook;
      bookingDetails.selectedSeatsNumbers = selectedSeatsNumbers;
      bookingDetails.selectedSeatTypes = selectedSeatTypes;
      return bookingDetails;
    } else {
      return "MAX_SEATS_ERROR";
      // console.log('Maximum Number of Seat Selection is reached');
      // this.util.showToast(this.util.tranlateInstant("max_four_seats_allowed"));
    }
  } catch (e) {
    console.log("SelectseatsController_selectSeatFunction", e);
  }
};

const TrainSeatLayout = (props) => {
  const {
    coachLayout,
    onWagonChange,
    selectedWagon,
    metaData,
    selectedSeatsToBook,
    upperAvalibility,
    selectedService,
    serviceDetails
  } = props;

  console.log("coachLayout", coachLayout);

  let wagonCoachLayout = coachLayout[selectedWagon];

  let seatRow = (seatDir) => {
    let rows = wagonCoachLayout.map((row, i) => (
      <div
        key={i}
        className="seat-row"
        // style={styles.seatRow}
      >
        {seatCol(row, seatDir)}
      </div>
    ));
    return rows;
  };

  let seatCol = (row, seatDir) => {
    let cols = row.map((seat, i) => {
      // if(selectedWagon == i){
      return (!(
        seat.isGangWay ||
        seat.isBreak ||
        (seat.number &&
          (seat.number.indexOf("GY") > -1 || seat.number.indexOf("gy") > -1))
      ) ||
        seat.number == "DR_IMG") &&
        seat.object.floor_no === selectedWagon &&
        seat.object.seat_direction === seatDir ? (
        seatItem(seat, i)
      ) : (
        <div className="train-seat-gangway" key={i} />
      );
      // }
      // else if(selectedWagon == 'upper'){
      //   return (
      //     (!(seat.isGangWay || seat.isBreak || (seat.number && (seat.number.indexOf('GY') > -1 || seat.number.indexOf('gy') > -1))) || seat.number == "DR_IMG") && !seat.isLower && seat.isUpper
      //     ?  seatItem(seat,i)
      //     : <div style={styles.gangway} key={i}/>
      //   )
      // }
    });
    return cols;
  };

  let seatItem = (seat, i) => {
    return (
      <div
        className="seat-layout-inner-container"
        key={i}
        onClick={() =>
          props.onSeatTap(
            selectSeat(
              seat,
              metaData,
              wagonCoachLayout,
              selectedSeatsToBook,
              selectedService
            )
          )
        }
      >
        <div
          className={
            "seat-column " +
            (seat.object.seat_direction == 2 ? "reverse-dir" : "normal-dir")
          }
        >
          {getSeatIcon(seat) ? (
            <img
              style={styles.seatTypeIcon}
              width={32}
              className={
                seat.number == "DR_IMG" ? "steering-img driver-seat-icon" : ""
              }
              src={getSeatIcon(seat)}
            />
          ) : null}
          <div className="seat-number">
            <PBText
              fontSize={"font8"}
              textAlign={"text-center"}
              fontColor={
                seat.isSelected
                  ? "white-text"
                  : seat.isReservable
                  ? "primary-font"
                  : "dark-font"
              }
              //  style={{ fontSize: 9, textAlign: "center" }}
            >
              {seat.number != "DR_IMG" ? seat.number : ""}
            </PBText>
          </div>
        </div>
      </div>
    );
  };

  let isSeatType = (type) => {
    for (let wLayout of coachLayout) {
      for (let row of wLayout) {
        for (let seat of row) {
          if (
            seat.type.toUpperCase() == type &&
            seat.number.toUpperCase().indexOf("GY") < 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  let getSeatType = (text, icon) => {
    return (
      <div
        className="seat-type-item"
        // style={styles.seatTypeItem}
      >
        <img
          className="seat-type-show-image train-layout-showcase-img"
          src={icon}
        />
        <span
          className={"font13 dark-font"}
          // style={styles.seatTypeText}
        >
          {text}
        </span>
      </div>
    );
  };

  let getTabs = () => {
    let tabs = [];
    for (let i = 0; i < coachLayout.length; i++) {
      tabs.push(
        <PBTouchable
          key={i}
          className={
            // selectedFloor == "upper"
            "tab-item-outer " + (selectedWagon === i ? "active black-text" : "")
          }
          onPress={() => onWagonChange(i)}
          // onPress={() => { selectedWagon = "wagon1"}}
        >
          <div className="tab-item train-tab-item">
            <PBText fontSize={"font11"} fontColor={"white-font"}>
              Vag #{i + 1}
            </PBText>
          </div>
        </PBTouchable>
      );
    }
    return tabs;
  };

  return (
    <div className="seat-layout-container">
      <div className="seat-details">
        <div className="seat-types">
          {isSeatType("EJECUTIVO") || isSeatType("CLASICO")
            ? getSeatType(
                "Clásico",
                "/assets/icons/seats/icon-seat-legend_executive.png"
              )
            : null}
          {isSeatType("SEMI CAMA")
            ? getSeatType(
                "Semi Cama",
                "/assets/icons/seats/icon-seat-legend_semi-bed.png"
              )
            : null}
          {isSeatType("PREMIUM")
            ? getSeatType(
                "Premium",
                "/assets/icons/seats/icon-seat-legend_vip.png"
              )
            : null}
          {isSeatType("SALON CAMA")
            ? getSeatType(
                "Salón Cama",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}
          {isSeatType("SALON SIN VENT")
            ? getSeatType(
                "Salón sin ventana",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}
          {isSeatType("PREFERENTE")
            ? getSeatType(
                "Preferente",
                "/assets/icons/seats/icon-seat-legend_vip.png"
              )
            : null}
        </div>

        <div className="legend-row-container">
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#ffcf57",
                borderColor: "#f89d44",
              }}
            ></div>
            <span className={"dark-font font14"} style={styles.legendText}>
              Disponibles
            </span>
          </div>
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#ff8a8b",
                borderColor: "#ff787a",
              }}
            ></div>
            <span className={"dark-font font14"}>Seleccionado</span>
          </div>
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#dbdbdb",
                borderColor: "#969494",
              }}
            ></div>
            <span className={"dark-font font14"} style={styles.legendText}>
              Reservado
            </span>
          </div>
        </div>
      </div>

      <div className="floor-and-seats-section train-floor-and-seats-section">
        {CommonService.isTrain() || serviceDetails.travel_id == 272 ? (
          <div className="floor-tabs-container train-floor-tabs-container">
            <div className="floor-tabs">{getTabs()}</div>
          </div>
        ) : null}

        <div
          className="seats-section-container-train"
          style={{
            paddingTop: upperAvalibility ? "10px" : 0,
            marginTop: upperAvalibility ? "17px" : 0,
          }}
        >
          <div className="coach-layout-front-text font8">
            <img
              className="front-text-image"
              src="/assets/carpool/front_train_arrow.png"
            />
            <span className="webkit-text-rotate-180">
              <PBText fontSize={"font11"}>
                El tren avanza en esta dirección.
              </PBText>
            </span>
          </div>
          <div className="seat-layout-inner train-seat-layout-inner">
            <div
            // contentContainerStyle={styles.contentContainer}
            >
              {seatRow(1)}
              <div className="seats-dir-gap"></div>
              {seatRow(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  //seat Layout container
  seatTypeItem: {
    alignItems: "center",
    marginBottom: 10,
  },
  seatTypeText: {
    fontSize: 13,
  },
  floorTabs: {
    flexDirection: "row",
    width: "100%",
  },
  tabItem: {
    padding: 15,
    paddingTop: 4,
    paddingBottom: 4,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "#ccc",
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  seatLayoutContainer: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    paddingTop: 13,
  },
  seatDetails: {
    width: "45%",
    height: "100%",
    padding: 10,
  },
  seatTypes: {
    flex: 1,
  },
  seatLegendContainer: {},
  legendRow: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  legendCircle: {
    width: 15,
    height: 15,
    backgroundColor: "#ccc",
    borderRadius: 15,
    borderWidth: 1,
    marginRight: 5,
  },

  seatLayout: {
    width: "55%",
    height: "120%",
  },
  layoutInner: {
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
    padding: 10,
    flex: 1,
  },
  seatRow: {
    flexDirection: "row",
  },
  seatColumn: {
    padding: 10,
    alignItems: "center",
  },
  gangway: {
    width: 16,
  },
  seatNumber: {
    position: "absolute",
    top: "50%",
    marginTop: -5,
    width: "100%",
    alignItems: "center",
  },

  // ----
};

export default TrainSeatLayout;
