const colors = {
    primaryBG: "#ff7f45",
    secondaryBG: "#FF5C60",
    primaryText: "#fff",
    greyBG: "#f6f6f6",
    menuItemText: "#666",
    menuItemBG: "#fff",
    textColor: "#464647",
    lightPrimary: "#fef1ec",
    greyIcons: "#bfbfbf",
    corporateOrange: '#fa5e00'
}

export default colors;