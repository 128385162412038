import React from 'react';
import { dimensions } from '../theme';

// import { View, StyleSheet, Image } from 'react-native';

const BGCarpoolInnerBottom = props => {
	return <div></div>;
	// return (
	// 	<div></div>
	// 	// <img
	// 	// 	src={'../assets/carpool/bgs/bg-inner-bottom.png'}
	// 	// 	className="main-bottom-image"
	// 	// 	style={{ zIndex: props.zIndex }}
	// 	// />
	// );
};

const styles = {
	mainBgImg: {
		width: dimensions.vw * 100,
		height: dimensions.vh * 50,
		resizeMode: 'stretch',
		position: 'absolute',
		top: dimensions.vh * 65,
		left: 0,
	},
};

export default BGCarpoolInnerBottom;
