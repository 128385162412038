// @flow
import ReducerUtilService from "../../services/reducerUtilService";
import {
  GET_FACEBOOK_DATA_SUCCESS,
  GET_META_DATA_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
  OPEN_MENU,
  CLOSE_MENU,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  SET_CITIES,
  RETURN_DATE_CHANGE,
  ONWARD_DATE_CHANGE,
  SELECT_CITY_LIST_TYPE,
  SELECT_ORIGIN,
  SELECT_DESTINATION,
  GET_SERVICES_SUCCESS,
  SHOW_HIDE_BUS_LOADER,
  GET_SERVICE_DETAILS_SUCCESS,
  SERVICE_SELECTED_ONWARD,
  SERVICE_SELECTED_RETURN,
  SELECT_SEAT_ONWARD,
  SELECT_SEAT_RETURN,
  SET_PASSENGER_DETAILS_ONWARD,
  SET_PASSENGER_DETAILS_RETURN,
  SET_CONTACT_DETAILS,
  SET_BOOKING_JSON_DETAILS,
  SET_BOOKING_API_RESPONSE,
  LOGIN_SUCCESS,
  SET_CUSTOMER_PROFILE,
  SET_COORDS,
  SET_MARKERS,
  SET_TICKET_DETAILS,
  SET_MY_TICKETS,
  SET_AT_TICKETS,
  SET_TRAIN_TICKETS,
  SET_KUPOS_GO_TICKETS,
  SET_TXN_HISTORY,
  SET_RECENT_CITIES,
  SET_RECENT_CITIES_TRAIN,
  GET_SERVICES_RETURN_SUCCESS,
  SET_MARKER_MODAL,
  SET_CITIES_FROM_HOME,
  SET_CITY_PAIRS_HOME,
  ORIGIN_SEARCH_SELECTED,
  DESTINATION_SEARCH_SELECTED,
  SET_CITIES_UNIQUE,
  SET_DEVICE_INFO,
  SCAN_QR,
  SET_QR_IMAGE,
  SHOW_QR_BG,
  SET_VIRTUAL_MONEY,

  //carpool
  SET_USER_MODE,
  CARPOOL_SET_PICKUP_LOCATION,
  CARPOOL_SET_DROP_LOCATION,
  CARPOOL_DATE_CHANGE,
  CARPOOL_SET_SEARCH_TRIPS,

  //carpool driver
  SET_LOCAL_DATA,
  SHOW_HIDE_CONTACT_MODAL,
  SET_THIRD_PARTY_APP,
  REGISTER_SET_USER_PHONE_DATA_FOR_OTP,
  GET_ENVIRONMENT_THINGS,
  SET_CITIES_UNIQUE_TRAIN,
  SET_ADD_TO_HOME_SCREEN,
  SAVE_TICKETS_TO_CANCEL,
  SET_INSTANT_DISCOUNT,
  SET_CONVENIOS,
  SET_TICKET_DETAILS_INT,
  SET_CHANGE_TICKET_IN_CANCELLATION,
  SET_IS_MOT,
  SET_MOT_VALIDATION,
  SET_BULK_SEATS,
  SET_BULK_PASSENGER_DATA,
  SET_IS_OTA_CANCELLATION,
} from "./actions";
import DateService from "../../../services/dateService";

export const DEFAULT = {
  loginData: null,
  customerData: null,
  isLoading: false,
  isMenuOpen: false,
  metaData: null,
  cities: null,
  citiesUnique: null,
  citiesUniqueTrain: null,
  citiesData: {
    origins: null,
    destinations: null,
    destinationsList: null,
  },
  originSearchSelected: {
    searchKey: "",
    searchData: null,
  },
  destinationSearchSelected: {
    searchKey: "",
    searchData: null,
  },
  selectedOrigin: null,
  selectedDestination: null,
  selectedOnwardDate: null,
  selectedReturnDate: null,
  selectedCityListType: null,
  recentCities: null,
  showBusLoader: false,
  serviceList: null,
  serviceListReturn: null,
  serviceDetails: null,
  selectedServiceOnward: {
    coachLayoutJson: null,
    originStageDetails: null,
    destinationStageDetails: null,
    boardingStage: null,
    droppingStage: null,
    route: null,
    selectedService: null,
  },
  selectedServiceReturn: {
    coachLayoutJson: null,
    originStageDetails: null,
    destinationStageDetails: null,
    boardingStage: null,
    droppingStage: null,
    route: null,
    selectedService: null,
  },
  bookingDetailsOnward: {
    totalBookingAmount: 0,
    netBookingAmount: 0,
    discountAmount: 0,
    selectedSeatsToBook: null,
    selectedSeatsNumbers: null,
    selectedSeatTypes: null,
  },
  bookingDetailsReturn: {
    totalBookingAmount: 0,
    netBookingAmount: 0,
    discountAmount: 0,
    selectedSeatsToBook: null,
    selectedSeatsNumbers: null,
    selectedSeatTypes: null,
  },
  passengerDetailsOnward: [
    {
      seatNumber: null,
      name: null,
      nationality: null,
      idCardType: null,
      idCardNumber: null,
    },
  ],
  passengerDetailsReturn: [],
  contactDetails: {
    mobile: null,
    email: null,
    confirmEmail: null,
  },
  bookingJSONDetails: null,
  bookingApiResponse: null,
  coords: {
    latitude: null,
    longitude: null,
  },
  markers: [],
  ticketDetails: null,
  myTickets: [],
  TxnHistory: [],
  virtualMoney: [],
  markerModal: null,
  deviceInfo: null,
  scanQR: true,
  qrImage: null,
  showQRBG: false,
  showContactModal: false,
  thirdPartyApp: null,

  //carpools ---
  userMode: "CUSTOMER",
  carpoolPickupLocation: null,
  carpoolDropLocation: null,
  carpoolSelectedDate: null,
  carpoolSelectedTime: null,
  carpoolSearchTrips: null,
  carpoolSelectedOngoingTrip: null,
  carpoolTripsList: null,
  carpoolOrderDetails: null,

  //carpool drivers ---
  carpoolDriverSetInfo: null,
  carpoolDriverPickupLocation: null,
  carpoolDriverDropLocation: null,
  carpoolDriverSelectedDate: null,
  carpoolDriverSelectedTime: null,
  carpoolDriverShowTripAlertModal: false,
  carpoolDriverCreatedTrip: null,
  carpoolDriverTripsList: null,
  carpoolDriverUpcomingHomeTrips: null,
  carpoolDriverSelectedTripDetails: null,
  carpoolDriverSelectedTripOrders: null,

  transferData: {
    selectedService: 0,
    selectedOrigin: null,
    selectedDate: DateService.getTodayString(),
    selectedTime: "00:00",
    selectedKupos: 1,
  },
  kuposGoData: {
    selectedServiceType: null,
    selectedVehicleType: null,
    selectedOrigin: null,
    selectedDestination: null,
    selectedDate: DateService.getTodayString(),
    selectedTime: "00:00",
    selectedKupos: 1,
    viaPoints: [],
    name: null,
    lastName: null,
    email: null,
    mobile: null,
    selectedServiceTypeReturn: null,
    selectedVehicleTypeReturn: null,
    selectedOriginReturn: null,
    selectedDestinationReturn: null,
    selectedDateReturn: DateService.getTodayString(),
    selectedTimeReturn: "00:00",
    selectedKuposReturn: 1,
    viaPointsReturn: [],
  },
  airportTransferRoutes: null,
  airportTransferSelectedServiceToBook: null,
  airportTransferReturnServiceToBook: null,
  bipCardToEdit: null,
  showAddToHomeScreen: false,
  convenios: "",
};

export default function data(state = DEFAULT, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginData: payload,
      };
    }
    case SET_CUSTOMER_PROFILE: {
      return {
        ...state,
        customerData: payload,
      };
    }
    case REGISTER_SET_USER_PHONE_DATA_FOR_OTP: {
      return {
        ...state,
        registerUserPhoneDataForOtp: payload,
      };
    }
    case OPEN_MENU: {
      return {
        ...state,
        isMenuOpen: true,
      };
    }
    case CLOSE_MENU: {
      return {
        ...state,
        isMenuOpen: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_FACEBOOK_DATA_SUCCESS: {
      return {
        ...state,
        user: payload,
      };
    }
    case GET_META_DATA_SUCCESS: {
      return {
        ...state,
        metaData: payload,
      };
    }
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: payload,
      };
    }
    case SET_CITIES: {
      return {
        ...state,
        citiesData: ReducerUtilService.setCities(state, payload),
      };
    }
    case SET_CITIES_UNIQUE: {
      return {
        ...state,
        citiesUnique: payload,
      };
    }
    case SET_CITIES_UNIQUE_TRAIN: {
      return {
        ...state,
        citiesUniqueTrain: payload,
      };
    }
    case SET_CITIES_FROM_HOME: {
      return {
        ...state,
        citiesData: payload,
      };
    }
    case SET_CITY_PAIRS_HOME: {
      return {
        ...state,
        cities: payload.citiesSuccess,
        citiesData: payload.setCities,
      };
    }
    case SELECT_CITY_LIST_TYPE: {
      return {
        ...state,
        selectedCityListType: payload,
      };
    }
    case SET_RECENT_CITIES: {
      return {
        ...state,
        recentCities: payload,
      };
    }
    case SET_RECENT_CITIES_TRAIN: {
      return {
        ...state,
        recentCitiesTrain: payload,
      };
    }
    case ORIGIN_SEARCH_SELECTED: {
      return {
        ...state,
        originSearchSelected: payload,
      };
    }
    case DESTINATION_SEARCH_SELECTED: {
      return {
        ...state,
        destinationSearchSelected: payload,
      };
    }
    case SELECT_ORIGIN: {
      return {
        ...state,
        selectedOrigin: payload,
      };
    }
    case SELECT_DESTINATION: {
      return {
        ...state,
        selectedDestination: payload,
      };
    }
    case ONWARD_DATE_CHANGE: {
      return {
        ...state,
        selectedOnwardDate: payload,
      };
    }
    case RETURN_DATE_CHANGE: {
      return {
        ...state,
        selectedReturnDate: payload,
      };
    }
    case SHOW_HIDE_BUS_LOADER: {
      return {
        ...state,
        showBusLoader: payload,
      };
    }
    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        serviceList: {
          routes: ReducerUtilService.getFormattedRoutes(payload.routes),
          stages: payload.stages,
        },
      };
    }
    case GET_SERVICES_RETURN_SUCCESS: {
      return {
        ...state,
        serviceListReturn: {
          routes: ReducerUtilService.getFormattedRoutes(payload.routes),
          stages: payload.stages,
        },
      };
    }
    case GET_SERVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        serviceDetails: payload,
      };
    }

    case SERVICE_SELECTED_ONWARD: {
      return {
        ...state,
        selectedServiceOnward: payload,
      };
    }
    case SERVICE_SELECTED_RETURN: {
      return {
        ...state,
        selectedServiceReturn: payload,
      };
    }
    case SELECT_SEAT_ONWARD: {
      return {
        ...state,
        bookingDetailsOnward: payload,
      };
    }
    case SELECT_SEAT_RETURN: {
      return {
        ...state,
        bookingDetailsReturn: payload,
      };
    }
    case SET_PASSENGER_DETAILS_ONWARD: {
      return {
        ...state,
        passengerDetailsOnward: payload,
      };
    }
    case SET_PASSENGER_DETAILS_RETURN: {
      return {
        ...state,
        passengerDetailsReturn: payload,
      };
    }
    case SET_CONTACT_DETAILS: {
      return {
        ...state,
        contactDetails: payload,
      };
    }
    case SET_BOOKING_JSON_DETAILS: {
      return {
        ...state,
        bookingJSONDetails: payload,
      };
    }
    case SET_BOOKING_API_RESPONSE: {
      return {
        ...state,
        bookingApiResponse: payload,
      };
    }
    case SET_COORDS: {
      return {
        ...state,
        coords: payload,
      };
    }
    case SET_MARKERS: {
      return {
        ...state,
        markers: payload,
      };
    }
    case SET_TICKET_DETAILS: {
      return {
        ...state,
        ticketDetails: payload,
      };
    }
    case SET_TICKET_DETAILS_INT: {
      return {
        ...state,
        ticketDetailsInt: payload,
      };
    }
    case SET_MY_TICKETS: {
      return {
        ...state,
        myTickets: payload,
      };
    }
    case SET_AT_TICKETS: {
      return {
        ...state,
        atTickets: payload,
      };
    }
    case SET_TRAIN_TICKETS: {
      return {
        ...state,
        trainTickets: payload,
      };
    }

    case SET_KUPOS_GO_TICKETS: {
      return {
        ...state,
        kuposGoTickets: payload,
      };
    }

    case GET_ENVIRONMENT_THINGS: {
      return {
        ...state,
        environmentThings: payload,
      };
    }
    case SET_TXN_HISTORY: {
      return {
        ...state,
        TxnHistory: payload,
      };
    }
    case SET_VIRTUAL_MONEY: {
      return {
        ...state,
        virtualMoney: payload,
      };
    }
    case SET_MARKER_MODAL: {
      return {
        ...state,
        markerModal: payload,
      };
    }
    case SET_DEVICE_INFO: {
      return {
        ...state,
        deviceInfo: payload,
      };
    }
    case SCAN_QR: {
      return {
        ...state,
        scanQR: payload,
      };
    }
    case SHOW_QR_BG: {
      return {
        ...state,
        showQRBG: payload,
      };
    }
    case SET_QR_IMAGE: {
      return {
        ...state,
        qrImage: payload,
      };
    }
    case SET_LOCAL_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case SHOW_HIDE_CONTACT_MODAL: {
      return {
        ...state,
        showContactModal: payload,
      };
    }
    case SET_THIRD_PARTY_APP: {
      return {
        ...state,
        thirdPartyApp: payload,
      };
    }

    //carpools
    case SET_USER_MODE: {
      return {
        ...state,
        userMode: payload,
      };
    }
    case CARPOOL_SET_PICKUP_LOCATION: {
      return {
        ...state,
        carpoolPickupLocation: payload,
      };
    }
    case CARPOOL_SET_DROP_LOCATION: {
      return {
        ...state,
        carpoolDropLocation: payload,
      };
    }
    case CARPOOL_DATE_CHANGE: {
      return {
        ...state,
        carpoolSelectedDate: payload,
      };
    }
    case CARPOOL_SET_SEARCH_TRIPS: {
      return {
        ...state,
        carpoolSearchTrips: payload,
      };
    }

    case SET_ADD_TO_HOME_SCREEN: {
      return {
        ...state,
        showAddToHomeScreen: payload,
      };
    }

    case SAVE_TICKETS_TO_CANCEL: {
      return {
        ...state,
        numberOfATSeatsTocancel: payload,
      };
    }

    case SET_INSTANT_DISCOUNT: {
      return {
        ...state,
        instantDiscount: payload,
      };
    }

    case SET_CONVENIOS: {
      return {
        ...state,
        convenios: payload,
      };
    }
    case SET_CHANGE_TICKET_IN_CANCELLATION: {
      return {
        ...state,
        is_change_ticket: payload,
      };
    }
    case SET_IS_MOT: {
      return {
        ...state,
        isMot: payload,
      };
    }
    case SET_MOT_VALIDATION: {
      return {
        ...state,
        motValidationData: payload,
      };
    }

    case SET_BULK_SEATS: {
      return {
        ...state,
        bulkSeats: payload,
      };
    }
    case SET_BULK_PASSENGER_DATA: {
      return {
        ...state,
        bulkPassengerData: payload,
      };
    }
    case SET_IS_OTA_CANCELLATION: {
      return {
        ...state,
        isOTACancellation: payload,
      };
    }
    default:
      return state;
  }
}
