import { AppData, CommonService, DateSerivce } from "../../services";
import React, { useEffect, useState } from "react";
import { commonStyles, dimensions } from "../../theme";

import DateService from "../../services/dateService";
import { IonIcon } from "@ionic/react";
import PBModal from "../PBModal";
import PBText from "../PBText";
import PBTouchable from "../PBTouchable";
import Rating from "./Rating";
import { fetchDataActionCreators } from "../../redux";

// import Icon from 'react-native-vector-icons/AntDesign';

const getAmenitiesImage = name => {
  switch (name) {
    case "air_condtion.png": {
      return "../assets/svgs/amenities/air_condtion.svg";
    }
    case "baggage.png": {
      return "../assets/svgs/amenities/baggage.svg";
    }
    case "charging_plug.png": {
      return "../assets/svgs/amenities/charging_plug.svg";
    }
    case "coffee.png": {
      return "../assets/svgs/amenities/coffee.svg";
    }
    case "food_new_icon.png": {
      return "../assets/svgs/amenities/food_new_icon.svg";
    }
    case "gaming.png": {
      return "../assets/svgs/amenities/gaming.svg";
    }
    case "handicap.png": {
      return "../assets/svgs/amenities/handicap.svg";
    }
    case "mobile_ticket.png": {
      return "../assets/svgs/amenities/mobile_ticket.svg";
    }
    case "movie.png": {
      return "../assets/svgs/amenities/movie.svg";
    }
    case "restrooms.png": {
      return "../assets/svgs/amenities/restrooms.svg";
    }
    case "snacks_new.png": {
      return "../assets/svgs/amenities/snacks_new.svg";
    }
    case "wifi.png": {
      return "../assets/svgs/amenities/wifi.svg";
    }
    default: {
      return "";
    }
  }
};

const getSeatName = rawSeat => {
  switch (rawSeat) {
    case "SALON CAMA":
      return "Salón Cama";
      break;
    case "CLASICO":
      return "Clásico";
      break;
    case "Salon Sin Vent":
      return "Salón sin vent";
      break;
    case "Salon Preferente":
      return "Salón Preferente";
      break;
    case "SALON PREFERENTE":
      return "Salón Preferente";
      break;
    case "SALON":
      return "Salón ";
      break;
    case "Salon":
      return "Salón ";
      break;
    case "BIO BIO SUPERIOR":
      return "Bio Bio Superior";
      break;
    case "SALON CAMA P":
      return "";
    case "SALON CAMA IND":
      return "";
    default:
      return CommonService.capitalize(rawSeat);
      break;
  }
};

const ServiceListItem = props => {
  console.log("serviceListItem", props);
  let service = props.service;
  let color = service.soldRoute ? { color: "#ccc" } : {};

  let boardingStages =
    props.service.boarding_stages && props.service.boarding_stages;

  const isPetSeat = (Object.keys(props.service.pet_seat_info) || []).length > 0;
  let seatTypes = () => {
    // console.log("hello ", service.seatType);
    let seatTypes = service.seatType.map((type, i) => (
      <div
        className={"seat-type-container "}
        // style={styles.seatTypeContainer}
        key={i}
      >
        <span className="font12 dark-font dark-font line-height-1-1">
          {getSeatName(type.split(":")[0])}
        </span>
        <span
          className={
            "font12  bold-text " +
            (service.soldRoute ? "sold-service dark-font" : "secondary-font")
          }
        >
          {CommonService.currency(type.split(":")[1]).split(",")[0]}
        </span>
      </div>
    ));
    return seatTypes;
  };

  let terminals = "";
  const getTerminals = boardingStages => {
    boardingStages &&
      boardingStages.split(",").map(val => {
        terminals +=
          val.split("|")[2] && val.split("|")[2] == 2
            ? (props.stageDetails && props.stageDetails[val.split("|")[0]]
                ? `<div class="list-terminals  font12" ><div class="blue-dot" ></div>${
                    props.stageDetails[val.split("|")[0]]
                  }`
                : "") +
              " - &nbsp;" +
              `<span class="bold-text">${DateSerivce.ampm(
                val.split("|")[1],
              )}</span>` +
              "</div>"
            : val.split("|")[2] && val.split("|")[2] == 1
            ? (props.stageDetails && props.stageDetails[val.split("|")[0]]
                ? `<div class="list-terminals  font12" >  <div class="blue-dot" ></div>${
                    props.stageDetails[val.split("|")[0]]
                  }`
                : "") +
              " - &nbsp;" +
              `<span class="bold-text" >${DateSerivce.ampm(
                val.split("|")[1],
              )}</span>` +
              "</div>"
            : (props.stageDetails && props.stageDetails[val.split("|")[0]]
                ? `<div class="list-terminals  font12" >  <div class="blue-dot" ></div>${
                    props.stageDetails[val.split("|")[0]]
                  }`
                : " ") +
              " - &nbsp;" +
              `<span  class="bold-text" >${DateSerivce.ampm(
                val.split("|")[1],
              )}</span>` +
              "</div>";

        // props.stagesData[val.split('|')[0]] + ' - ' + val.split('|')[1] + '\n';
      });
  };

  console.log("boardingStages", boardingStages);
  console.log("get terminals", getTerminals(boardingStages));

  let amenities = () => {
    let amenities = service.operator_details[3].map((am, i) =>
      i < (isPetSeat ? 2 : 3)
        ? // <img
          //   key={i}
          //   className="amenity"
          //   // style={styles.amenity}
          //   height={15}
          //   src={getAmenitiesImage(props.amenities[am].toLowerCase())}
          // />
          null
        : null,
    );
    if (isPetSeat)
      amenities.unshift(
        <img
          className="amenity"
          // style={styles.amenity}
          height={25}
          src={"/assets/seats/PetsOnBoardIIcon.svg"}
          alt=""
        />,
      );
    return amenities;
  };

  console.log("onSerivceClicked", props.onSerivceClicked);

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        className="item-container-outer kupos-card"
        onClick={props.onSerivceClicked}
        // style={styles.itemContainerOuter}
      >
        <div
          className="item-container "
          // style={styles.itemContainer}
        >
          <div className="op-name-row flex-row">
            <div
              className="flex-row"
              style={{
                alignItems: "center",
              }}
            >
              <img
                className={
                  "operator-image" + (service.soldRoute ? " sold-service" : "")
                }
                src={service.operator_details[0]}
                alt="p"
              />
              <div
                className={
                  "font12 on-name" + (service.soldRoute ? " sold-service" : "")
                }
              >
                {service.operator_service_name.toUpperCase()}
              </div>
              {props.service.is_direct_trip && (
                <div className="direct-service-ui">
                  <img
                    src="/assets/images/Icon-Mobile-Background.png"
                    alt=""
                    className="bg"
                  />
                  <div className="wrapper">
                    <img
                      src="/assets/images/Icon-Mobile.png"
                      alt=""
                      className="direct-arrow"
                    />
                    <span className="directo bold-text">DIRECTO</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className="p-1"
              onClick={e =>
                props.onInfoButtonClick(
                  e,
                  props.service && props.service.terminal_info
                    ? props.service.terminal_info
                    : "",
                )
              }
            >
              <img src="/assets/icons/information-icon.svg" alt="" width={20} />
            </div>
            {/* {getTerminals(boardingStages)} */}
          </div>
          <div className="service-details-row flex-row">
            <div className="amenities-date">
              <div className="time-row flex-row">
                <div className="time-info black-text dark-font">
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.dep_time.lastIndexOf(":") > 4
                      ? service.dep_time.substr(0, 5)
                      : service.dep_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.dep_time.lastIndexOf(":") > 4
                        ? service.dep_time.substr(0, 5)
                        : service.dep_time,
                    )}
                  </span>
                </div>
                <div
                  className={
                    "time-seperator font10 light-text" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span className={service.soldRoute ? " sold-service" : ""}>
                    {service.duration} horas
                  </span>
                  {/* <img src="/assets/svgs/two-ways-arrow.svg" /> */}
                  <img src="/assets/icons/trip-duration-scroll-down-arrow-one-direction.svg" />
                  {/* <div className="border-bottom-item-list"></div> */}
                </div>

                {/* <div className="duration-hours">
                <span className="duration">{service.duration}</span>
                <img src="../assets/svgs/two-ways-arrow.svg" />
                <span className="hours">Horas</span>
              </div> */}
                <div
                  className={
                    "time-info black-text dark-font" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.arr_time.lastIndexOf(":") > 4
                      ? service.arr_time.substr(0, 5)
                      : service.arr_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.arr_time.lastIndexOf(":") > 4
                        ? service.arr_time.substr(0, 5)
                        : service.arr_time,
                    )}
                  </span>
                </div>
              </div>

              <div
                className={
                  "date-row flex-row font11 light-text space-between  light-text" +
                  (service.soldRoute ? " sold-service" : "")
                }
              >
                <span className="">
                  {DateService.getServiceItemDate(service.travel_date)}
                </span>
                <span className="">
                  {DateService.getServiceItemDate(service.arrival_date)}
                </span>
              </div>
              <div
                className="view-flex amenities-rating-container"
                // style={{ ...styles.viewFlex, paddingTop: 4 }}
              >
                <div
                  className="amenities-container"
                  // style={styles.amenitiesContainer}
                >
                  {amenities()}
                </div>
              </div>
            </div>
            <div
              className={
                "view-flex seat-type-container-inner " +
                (service.soldRoute ? "sold-service" : "")
              }
              // style={{ ...styles.viewFlex, ...styles.marginTop }}
            >
              {/* <PBText style={{...styles.opName,...styles.hidden}}>J</PBText> */}
              {seatTypes()}
              {service.soldRoute ? (
                <div
                  className={
                    "sold-out-text " + (service.soldRoute ? "sold-service" : "")
                  }
                  // style={styles.soldOutText}
                >
                  <span
                    className={"font12"}
                    // style={{ ...color, ...styles.textMarginTop }}
                  >
                    Agotado
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {service.offer_text && (
        <div className="offer-text font11">
          <span className="offer-percent font13 bold-text">%</span>
          <span>
            {service.offer_text.split("[[")[0]}{" "}
            {service.offer_text.split("[[")[1] ? (
              <span className="bold-text" style={{ color: "#003ba7" }}>
                {" "}
                {`"${service.offer_text.split("[[")[1]}"`}
              </span>
            ) : null}{" "}
            {service.offer_text.split("[[")[2]}
          </span>
        </div>
      )}
    </div>
  );
};

const styles = {
  itemContainerOuter: {
    width: "100%",
    display: "flex",
  },
  itemContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  viewFlex: {
    width: dimensions.vw * 31,
    padding: 5,
    paddingVertical: 10,
    alignItems: "center",
  },
  arrow: {
    padding: 3,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  amenitiesContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 3,
    paddingLeft: 7,
  },
  amenity: {
    marginRight: 8,
  },
  serviceDetails: {
    alignItems: "flex-start",
    marginTop: 321,
  },
  opName: {
    fontSize: 12,
    position: "absolute",
    top: 9,
    left: 100,
  },
  marginTop: {
    marginTop: 16,
  },
  time: {
    fontSize: 10,
  },
  duration: {
    fontSize: 10,
  },
  hidden: {
    color: "#fff",
  },
  textMarginTop: {},
  seatTypeContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  seatType: {
    fontSize: 10,
  },
  seatFare: {
    fontSize: 10,
  },
  soldOutText: {
    width: "100%",
    alignItems: "flex-start",
    fontSize: 12,
  },
};

export default ServiceListItem;
