import React, { useState } from 'react'
import { CommonService } from '../services'
import PBInput from './PBInput'
import PBModal from './PBModal'
import PButton from './PButton'

const initialState = {
    resetPassword: '',
    resetConfirmPassword: '',
    resetPasswordError: '',
    resetConfirmPasswordError: '',
}

const ResetNewPasswordModal = ({ showModal, setShowModal, resetPassword }) => {
    const [values, setValues] = useState(initialState)

    const handleChange = (val, type) => {
        setValues({ ...values, [type]: val, [type + 'Error']: null })
    }


    const handleResetPassword = () => {
        if (!values.resetPassword) {
            setValues({
                ...values,
                resetPasswordError: "Introduzca su contraseña",
            });
            return;
        } else {
            if (values.resetPassword.length < 4) {
                setValues({
                    ...values,
                    resetPasswordError:
                        "Nueva contraseña/longitud de la contraseña debe ser al menos 4 caracteres",
                });
                return;
            }
        }
        if (values.resetPassword != values.resetConfirmPassword) {
            setValues({
                ...values,
                resetConfirmPasswordError: "Las contraseñas deben coincidir.",
            });
            return;
        }

        let data = {
            user: {
                password: values.resetPassword,
                password_confirmation: values.resetConfirmPassword,
                reset_password_token: CommonService.getParameterByName(
                    "reset_password_token"
                ),
            },
        };


        // calling the resetPassword from MainHomeScreen
        resetPassword(data)
    }


    return (
        <PBModal
            // showModal={true}
            showModal={showModal}
            showButton1={false}
            showButton2={false}
            onBackButtonPress={setShowModal}
            titleText={"Recupera tu contraseña"}
            showCloseIcon={true}
            closeIconPress={setShowModal}
        >
            <div className="reset-modal-inp">
                <div className="inp">
                    <PBInput
                        label="Ingresa tu contraseña"
                        type="password"
                        className="kupos-border"
                        placeholder="Ingresa tu contraseña"
                        onChange={(text) => handleChange(text, 'resetPassword')}
                        onBlur={() => { }}
                        value={values.resetPassword}
                        error={values.resetPasswordError ? true : false}
                        errorMessage={values.resetPasswordError}
                    />
                </div>
                <div className="inp">
                    <PBInput
                        label="Confirma tu contraseña"
                        type="password"
                        className="kupos-border"
                        placeholder="Confirma tu contraseña"
                        onChange={(text) => handleChange(text, 'resetConfirmPassword')}
                        onBlur={() => { }}
                        value={values.resetConfirmPassword}
                        error={values.resetConfirmPasswordError ? true : false}
                        errorMessage={values.resetConfirmPasswordError}
                    />
                </div>
                <div style={styles.buttonsContainer}>
                    <div style={styles.buttonContainer}>
                        <PButton onClick={handleResetPassword} title={"CONTINUAR"} />
                    </div>
                </div>
            </div>
        </PBModal>
    )
}
const styles = {

}

export default ResetNewPasswordModal
