// @flow

import { App_Service, AppData } from "../../../services";

import { take, put, call, fork, all } from "redux-saga/effects";
import { groupBy } from "lodash";

import {
  GET_FACEBOOK_DATA,
  fetchDataActionCreators,
  GET_META_DATA,
  GET_CITIES,
  GET_CITY_PAIRS,
  GET_SERVICES,
  GET_SERVICE_DETAILS,
  BOOK_TENTATIVE_TICKET,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  ACCOUNT_CONFIRMATION,
  GET_CUSTOMER_PROFILE,
  GET_WALLET_RECHARGE_REQUEST,
  GET_RECHARGE_LOCATIONS,
  GET_TICKET_DETAILS,
  CANCEL_TICKET,
  CANCEL_TICKET_AT,
  GET_MY_TICKETS,
  GET_AT_TICKETS,
  GET_TRAIN_TICKETS,
  GET_KUPOS_GO_TICKETS,
  GET_TICKET_DETAILS_KUPOS,
  GET_TXN_HISTORY,
  GET_RECIPIENT_INFO,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_FAVOURITES,
  TRANSFER_MONEY,
  REQUEST_MONEY,
  APPLY_PROMO_CODE,
  GET_MARKER_ICONS,
  GET_CITIES_SEARCH,
  GET_CITIES_UNIQUE,
  GET_QR_IMAGE,
  GET_QR_INFO,
  GET_VIRTUAL_MONEY,
  GET_PLACES,
  RESET_NEW_PASSWORD,
  KUPOS_GO_POST_TRIP_DETAILS,
  GENERATE_OTP,
  VALIDATE_OTP,
  GET_ENVIRONMENT_THINGS,
  VALIDATE_CUSTOMER,
  GET_TICKET_DETAILS_AT,
  LOGOUT_GOOGLE,
  GET_CITIES_UNIQUE_TRAIN,
  GET_CUSTOMER_CARDS,
  DELETE_MY_CARDS,
  ADD_MY_CARDS,
  GET_CUSTOMER_INFO,
  GET_INSTANT_DISCOUNT,
  GET_CORPORATE_DISCOUNT,
  GET_SERVICES_V2,
  GET_SERVICE_DETAILS_V2,
  GET_CONVENIOS,
  APPLY_PULLMAN_DISCOUNT,
  GET_TICKET_DETAILS_INT,
  ADD_CHANGE_TICKET,
  GET_API_CALL,
  POST_API_CALL,
  PUT_API_CALL,
  DELETE_API_CALL,
  VALIDATE_MOT,
  VALIDATE_MOT_SMS,
  GET_DIRECT_CANCEL_OPERATORS,
  GET_DIRECT_CANCELLATION_TICKET_DETAILS,
  IS_CANCEL_DIRECT,
} from "./actions";

import CommonService from "../../../services/commonService";
import ReducerUtilService from "../../services/reducerUtilService";

const callback = (response, callback) => {
  if (callback)
    if (response.result === "ok" && response.data) {
      callback({ success: true, data: response.data });
    } else {
      callback({
        success: false,
        error: "Login Failed try after sometime",
      });
    }
};

export function* asyncGetFacebookUserData({ payload }) {
  const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = `https://graph.facebook.com/v2.11/me?access_token=${facebookToken}&fields=id,name,email,picture{url}`;

  try {
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok") {
      yield put(
        fetchDataActionCreators.getFacebookUserDataSuccess(response.data)
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchGetFacebookUserData() {
  while (true) {
    const action = yield take(GET_FACEBOOK_DATA);
    yield* asyncGetFacebookUserData(action);
  }
}

export function* login({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/login?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Login Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchLogin() {
  while (true) {
    const action = yield take(LOGIN);
    yield* login(action);
  }
}

// Get Api Call ----
export function* asyncGetApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetApiCall() {
  while (true) {
    const action = yield take(GET_API_CALL);
    yield* asyncGetApiCall(action);
  }
}

// POST Api Call ----
export function* asyncPostApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}
export function* watchPostApiCall() {
  while (true) {
    const action = yield take(POST_API_CALL);
    yield* asyncPostApiCall(action);
  }
}
// PUT Api Call ----
export function* asyncPutApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "PUT",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchPutApiCall() {
  while (true) {
    const action = yield take(PUT_API_CALL);
    yield* asyncPutApiCall(action);
  }
}
// DELETE Api Call ----
export function* asyncDeleteApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "DELETE",
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchDeleteApiCall() {
  while (true) {
    const action = yield take(DELETE_API_CALL);
    yield* asyncDeleteApiCall(action);
  }
}

export function* register({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/register?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchRegister() {
  while (true) {
    const action = yield take(REGISTER);
    yield* register(action);
  }
}

export function* googleLogout({ payload }) {
  // eslint-disable-next-line
  // yield put(fetchDataActionCreators.showLoader());
  const url =
    "https://accounts.google.com/o/oauth2/revoke?token=" + payload.token;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      noContentJson: true,
      noAuth: true,
    });
    // yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    // yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGoogleLogout() {
  while (true) {
    const action = yield take(LOGOUT_GOOGLE);
    yield* googleLogout(action);
  }
}

export function* forgotPassword({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/request_password?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchForgotPassword() {
  while (true) {
    const action = yield take(FORGOT_PASSWORD);
    yield* forgotPassword(action);
  }
}

export function* validateCustomer({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/validate_customer";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateCustomer() {
  while (true) {
    const action = yield take(VALIDATE_CUSTOMER);
    yield* validateCustomer(action);
  }
}

export function* accountConfirmation({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/confirm_account?confirmation_token=" +
    payload.token;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchAccountConfirmation() {
  while (true) {
    const action = yield take(ACCOUNT_CONFIRMATION);
    yield* accountConfirmation(action);
  }
}

export function* resetNewPassword({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/change_password?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchResetNewPassword() {
  while (true) {
    const action = yield take(RESET_NEW_PASSWORD);
    yield* resetNewPassword(action);
  }
}

export function* asyncGetMetaData({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/masters.json?";

  try {
    // if(!payload.metaData)
    //   yield put(fetchDataActionCreators.showLoader());
    const response = yield call(App_Service, { url, method: "GET" });

    // console.log("response ----------------->", response);
    if (response.result === "ok" && response.data.result) {
      yield put(
        fetchDataActionCreators.getMetaDataSuccess(response.data.result)
      );
    }
    payload.callback();
    // if(!payload.metaData)
    //   yield put(fetchDataActionCreators.hideLoader());
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetMetaData() {
  while (true) {
    const action = yield take(GET_META_DATA);
    yield* asyncGetMetaData(action);
  }
}

export function* asyncGetCities({ payload }) {
  // eslint-disable-next-line

  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = AppData.BASE_URL + "api/uniq_cities.json?";
    const response = yield call(App_Service, { url, method: "GET" });

    // console.log("response ----------------->", response);
    let cities;
    if (response.result === "ok" && response.data.body) {
      cities = CommonService.formatData(response.data.body);
      // yield put(fetchDataActionCreators.getCitiesSuccess(cities));
      payload.callback({ success: true, citiesSuccess: cities });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* asyncGetCityPairs({ payload }) {
  try {
    // console.log('hlhjhkhkjkjhkhhkjhkjhkjhhjhh')
    const url =
      "https://gds.ticketsimply.us/gds/api/city_pairs.json?&api_key=TSQPGXAPI33366111";
    // url = AppData.BASE_URL + 'api/city_pairs.json?';
    const response2 = yield call(App_Service, { url, method: "GET" });

    console.log("response2 ----------------->", response2);
    if (response2.result === "ok" && response2.result) {
      // yield put(fetchDataActionCreators.setCities(response2.data.result));
      if (payload.callback) {
        payload.callback({ success: true, setCities: response2.data.result });
      }
    }
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCities() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES);
    yield call(() => asyncGetCities(action));

    // console.log("888888888888888888888888888888888888888888888888888888")
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCityPairs() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITY_PAIRS);
    yield* asyncGetCityPairs(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* asyncGetCitiesUnique({ payload }) {
  // eslint-disable-next-line

  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = AppData.BASE_URL + "api/uniq_cities";
    const response = yield call(App_Service, { url, method: "GET" });

    // console.log("response ----------------->", response);
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      // yield put(fetchDataActionCreators.getCitiesUniqueSuccess(cities));
      payload.callback({ success: true, cities: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCitiesUnique() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES_UNIQUE);
    yield* asyncGetCitiesUnique(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* asyncGetCitiesUniqueTrain({ payload }) {
  // eslint-disable-next-line

  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = AppData.BASE_URL + "api/train_cities";
    const response = yield call(App_Service, { url, method: "GET" });

    console.log("response ----------------->", response);
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      // yield put(fetchDataActionCreators.getCitiesUniqueTrainSuccess(cities));
      payload.callback({ success: true, cities: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCitiesUniqueTrain() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES_UNIQUE_TRAIN);
    yield* asyncGetCitiesUniqueTrain(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

// cities search start ------------

export function* asyncGetCitiesSearch({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    "api/get_city?term=" +
    payload.term +
    (payload.originId ? "&origin_id=" + payload.originId : "");

  try {
    const response = yield call(App_Service, { url, method: "GET" });
    console.log("asyncGetCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: true });
    console.log(e);
  }
}

export function* watchGetCitiesSearch() {
  while (true) {
    const action = yield take(GET_CITIES_SEARCH);
    yield* asyncGetCitiesSearch(action);
  }
}
//cities search end -------------

// customer profile start ------------

export function* asyncGetCustomerProfile({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/customer/profile?";

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET" });
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    console.log("asyncGetCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCustomerProfile() {
  while (true) {
    const action = yield take(GET_CUSTOMER_PROFILE);
    yield* asyncGetCustomerProfile(action);
  }
}
//customer profile end -------------

// update customer profile start ------------

export function* asyncUpdateCustomerProfile({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/customer/update_profile?";

  try {
    yield put(fetchDataActionCreators.showLoader());
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("asyncUpdateCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchUpdateCustomerProfile() {
  while (true) {
    const action = yield take(UPDATE_CUSTOMER_PROFILE);
    yield* asyncUpdateCustomerProfile(action);
  }
}
// update customer profile end -------------

export function* asyncGetServices({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    (CommonService.isTrain()
      ? "api/train_schedules.json?"
      : "api/ui_schedules.json?") +
    payload.path;
  console.log(url);
  try {
    if (!payload.hideLoader)
      yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data.body) {
      let schedules = response.data.body;
      let keys = schedules[0];
      let schedulesArr = [];
      for (let i = 1; i < schedules.length; i++) {
        let currentObj = {};
        for (let j = 0; j < schedules[i].length; j++) {
          currentObj[keys[j]] = schedules[i][j];
        }
        schedulesArr.push(currentObj);
      }

      let res = {
        routes: schedulesArr,
        stages: schedules.stage_names,
        success: true,
        maxRetryCount: response.data.maxRetryCount,
        retryFlag: response.data.retryFlag,
        waitTime: response.data.waitTime,
      };
      payload.callback(res);
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServicesReturnSuccess(res));
      // }else{
      //   yield put(fetchDataActionCreators.getServicesSuccess(res));
      // }
      console.log(res);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetServices() {
  while (true) {
    const action = yield take(GET_SERVICES);
    yield* asyncGetServices(action);
  }
}

// update customer profile end -------------

export function* asyncGetServicesV2({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + `api/v2/scheduled_services?` + payload.path;

  console.log(url);
  try {
    if (!payload.hideLoader)
      yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data) {
      payload.callback(response.data);
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServicesReturnSuccess(res));
      // }else{
      //   yield put(fetchDataActionCreators.getServicesSuccess(res));
      // }
      console.log(response);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetServicesV2() {
  while (true) {
    const action = yield take(GET_SERVICES_V2);
    yield* asyncGetServicesV2(action);
  }
}

export function* getServiceDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/ui_schedule.json?schedule_id=${payload.data.scheduleId}&is_round_trip=${payload.data.returnTrip}&is_roundtrip_return=${payload.data.returnTripSearch}`;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data.body) {
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServiceDetailsReturnSuccess(ReducerUtilService.makeServiceDetailsApi(response.data.body)));
      // }else{

      if (response.data && response.data.body.message) {
        payload.callback(false, response.data.body.message);
        return;
      }
      yield put(
        fetchDataActionCreators.getServiceDetailsSuccess(
          ReducerUtilService.makeServiceDetailsApi(response.data.body)
        )
      );
      // }
      payload.callback(true);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback(false);
    console.log(e);
  }
}

export function* watchGetServiceDetails() {
  while (true) {
    const action = yield take(GET_SERVICE_DETAILS);
    yield* getServiceDetails(action);
  }
}

export function* getServiceDetailsV2({ payload }) {
  // eslint-disable-next-line
  if (!payload.hideLoader) {
    yield put(fetchDataActionCreators.showLoader());
  }
  const url =
    AppData.BASE_URL + `api/v2/scheduled_services/${payload.data.scheduleId}`;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data.body) {
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServiceDetailsReturnSuccess(ReducerUtilService.makeServiceDetailsApi(response.data.body)));
      // }else{

      if (response.data && response.data.body.message) {
        payload.callback(false, response.data.body.message, response.data);
        return;
      }
      yield put(
        fetchDataActionCreators.getServiceDetailsSuccess(
          ReducerUtilService.makeServiceDetailsApi(response.data.body)
        )
      );
      // }
      payload.callback(true, "", response.data.body);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback(false);
    console.log(e);
  }
}

export function* watchGetServiceDetailsV2() {
  while (true) {
    const action = yield take(GET_SERVICE_DETAILS_V2);
    yield* getServiceDetailsV2(action);
  }
}

export function* asyncGetTrainServices({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/ui_schedules.json?" + payload.path;
  console.log(url);
  try {
    yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data.body) {
      let schedules = response.data.body;
      let keys = schedules[0];
      let schedulesArr = [];
      for (let i = 1; i < schedules.length; i++) {
        let currentObj = {};
        for (let j = 0; j < schedules[i].length; j++) {
          currentObj[keys[j]] = schedules[i][j];
        }
        schedulesArr.push(currentObj);
      }

      let res = {
        routes: schedulesArr,
        stages: schedules.stage_names,
        success: true,
      };
      payload.callback(res);
      console.log(res);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetTrainServices() {
  while (true) {
    const action = yield take(GET_SERVICES);
    yield* asyncGetServices(action);
  }
}

export function* bookTentativeTicket({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    (payload.V2
      ? "api/v2/bus_bookings?is_mobile_app=true"
      : !payload.isRoundTrip
      ? "api/tentative_booking.json?is_mobile_app=true"
      : "api/round_trip.json?is_mobile_app=true");
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.bookingJson,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("bookTentativeTicket", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchBookTentativeTicket() {
  while (true) {
    const action = yield take(BOOK_TENTATIVE_TICKET);
    yield* bookTentativeTicket(action);
  }
}

export function* asyncGetWalletRechargeRequest({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/wallet_recharge_request";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("getWalletRechargeRequest", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetWalletRechargeRequest() {
  while (true) {
    const action = yield take(GET_WALLET_RECHARGE_REQUEST);
    yield* asyncGetWalletRechargeRequest(action);
  }
}

export function* asyncGetRechargeLocations({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/recharge_locations?" + payload.path;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetRechargeLocations", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetRechargeLocations() {
  while (true) {
    const action = yield take(GET_RECHARGE_LOCATIONS);
    yield* asyncGetRechargeLocations(action);
  }
}

export function* asyncGetRecipientInfo({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "api/customer_info?recipient_rut_no=" + payload.rut;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetRecipientInfo", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetRecipientInfo() {
  while (true) {
    const action = yield take(GET_RECIPIENT_INFO);
    yield* asyncGetRecipientInfo(action);
  }
}

export function* asyncUpdateFavourites({ payload }) {
  // eslint-disable-next-line
  // yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/favourites";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    // yield put(fetchDataActionCreators.hideLoader());
    console.log("getWalletRechargeRequest", response, payload);
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
    }
  } catch (e) {
    // yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchUpdateFavourites() {
  while (true) {
    const action = yield take(UPDATE_FAVOURITES);
    yield* asyncUpdateFavourites(action);
  }
}

export function* asyncTransferMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/transfer_money?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("TransferMoney", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchTransferMoney() {
  while (true) {
    const action = yield take(TRANSFER_MONEY);
    yield* asyncTransferMoney(action);
  }
}

export function* asyncRequestMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/kupos_pay/request_money?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("RequestMoney", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchRequestMoney() {
  while (true) {
    const action = yield take(REQUEST_MONEY);
    yield* asyncRequestMoney(action);
  }
}

export function* asyncGetTicketDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/ticket_details.json?pnr_number=" +
    payload.pnrNumber +
    (payload.path && "&" + payload.path);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTicketDetails", response, payload);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        yield put(fetchDataActionCreators.setTicketDetails(response.data.body));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
    setTimeout(() => fetchDataActionCreators.hideLoader(), 300);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTicketDetails() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS);
    yield* asyncGetTicketDetails(action);
  }
}

// TICKET DETAILS INT
export function* asyncGetTicketDetailsInt({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/pullman/bus_bookings/int_ticket_details.json?is_mobile_app=true&pnr_number=" +
    payload.pnrNumber;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTicketDetailsInt", response, payload);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        yield put(
          fetchDataActionCreators.setTicketDetailsInt(response.data.body)
        );
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
    setTimeout(() => fetchDataActionCreators.hideLoader(), 300);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTicketDetailsInt() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS_INT);
    yield* asyncGetTicketDetailsInt(action);
  }
}

export function* asyncAddChangeTicket({ payload }) {
  // eslint-disable-next-line
  console.log("payload for change ticket api is", payload);
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "api/pullman/bus_bookings/validate_change_boleto";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    console.log("response in saga", response);
    yield put(fetchDataActionCreators.hideLoader());

    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        // yield put(fetchDataActionCreators.setTicketDetailsInt(response.data.body));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
    setTimeout(() => fetchDataActionCreators.hideLoader(), 300);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchAddChangeTicket() {
  while (true) {
    const action = yield take(ADD_CHANGE_TICKET);
    yield* asyncAddChangeTicket(action);
  }
}

export function* asyncGetMyTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/my_tickets?page=1&per=10";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetMyTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        yield put(
          fetchDataActionCreators.setMyTickets(response.data.body.transactions)
        );
      } else {
        yield put(fetchDataActionCreators.setMyTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetMyTickets() {
  while (true) {
    const action = yield take(GET_MY_TICKETS);
    yield* asyncGetMyTickets(action);
  }
}

//Get Train tickets
export function* asyncGetTrainTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api/my_tickets?page=1&per=10&cat_type=train";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTrainTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (
        response.data &&
        response.data.success &&
        response.data.body &&
        response.data.body.transactions
      ) {
        yield put(
          fetchDataActionCreators.setTrainTickets(
            response.data.body.transactions.sort((a, b) =>
              a.travel_date <= b.travel_date ? 1 : -1
            )
          )
        );
      } else {
        yield put(fetchDataActionCreators.setTrainTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTrainTickets() {
  while (true) {
    const action = yield take(GET_TRAIN_TICKETS);
    yield* asyncGetTrainTickets(action);
  }
}

//Get KuposGo tickets
export function* asyncGetKuposGoTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "/api/my_tickets?page=1&per=10&cat_type=kuposgo";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetKuposGoTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (
        response.data &&
        response.data.success &&
        response.data.body &&
        response.data.body.transactions
      ) {
        yield put(
          fetchDataActionCreators.setKuposGoTickets(
            response.data.body.transactions.sort((a, b) =>
              a.travel_date <= b.travel_date ? 1 : -1
            )
          )
        );
      } else {
        yield put(fetchDataActionCreators.setKuposGoTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetKuposGoTickets() {
  while (true) {
    const action = yield take(GET_KUPOS_GO_TICKETS);
    yield* asyncGetKuposGoTickets(action);
  }
}

//get Kupos Ticket Details
export function* asyncGetKuposTicketDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `/api/kupos_ticket_details?id=${payload.id}&cat_type=${payload.cat_type}`;
  console.log("Ticket details API URL", url);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTicketDetailsAT", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetKuposTicketDetails() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS_KUPOS);
    yield* asyncGetKuposTicketDetails(action);
  }
}

//Get Environmental Things
export function* asyncwatchGetEnvironmentThings({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/env_stats";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetEnvironmentalThings", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchwatchGetEnvironmentThings() {
  while (true) {
    const action = yield take(GET_ENVIRONMENT_THINGS);
    yield* asyncwatchGetEnvironmentThings(action);
  }
}

export function* asyncCancelTicket({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/cancel_ticket.json?" + payload.path;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("CancelTicket", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchCancelTicket() {
  while (true) {
    const action = yield take(CANCEL_TICKET);
    yield* asyncCancelTicket(action);
  }
}

export function* asyncGetTxnHistory({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/wallets/histories?" +
    (payload.path && "&" + payload.path);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTxnHistory", response, payload);
    if (response.result === "ok" && response.data) {
      if (!response.data.error)
        yield put(fetchDataActionCreators.setTxnHistory(response.data));
      payload.callback({
        success: true,
        data: response.data,
        statusCode: response.statusCode,
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTxnHistory() {
  while (true) {
    const action = yield take(GET_TXN_HISTORY);
    yield* asyncGetTxnHistory(action);
  }
}

export function* asyncApplyPromoCode({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/apply_promo_code.json?is_mobile_app=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("ApplyPromoCode", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchApplyPromoCode() {
  while (true) {
    const action = yield take(APPLY_PROMO_CODE);
    yield* asyncApplyPromoCode(action);
  }
}

export function* asyncGetVirtualMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/wallets/vw_histories?" +
    (payload.path && "&" + payload.path);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetVirtualMoney", response, payload);
    if (response.result === "ok" && response.data) {
      if (!response.data.error)
        yield put(fetchDataActionCreators.setVirtualMoney(response.data));
      payload.callback({
        success: true,
        data: response.data,
        statusCode: response.statusCode,
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetVirtualMoney() {
  while (true) {
    const action = yield take(GET_VIRTUAL_MONEY);
    yield* asyncGetVirtualMoney(action);
  }
}

export function* asyncGetMarkerIcons({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/marker_icons";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetMarkerIcons", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetMarkerIcons() {
  while (true) {
    const action = yield take(GET_MARKER_ICONS);
    yield* asyncGetMarkerIcons(action);
  }
}

export function* asyncGetQrImage({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/qrcode/qr_image_data";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
      yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetQrImage() {
  while (true) {
    const action = yield take(GET_QR_IMAGE);
    yield* asyncGetQrImage(action);
  }
}

export function* asyncGetQrInfo({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/qrcode/info";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetQrInfo() {
  while (true) {
    const action = yield take(GET_QR_INFO);
    yield* asyncGetQrInfo(action);
  }
}

export function* asyncGenerateOtp({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/generate_sms_code";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGenerateOtp() {
  while (true) {
    const action = yield take(GENERATE_OTP);
    yield* asyncGenerateOtp(action);
  }
}

export function* asyncValidateOtp({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/validate_sms_code";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchValidateOtp() {
  while (true) {
    const action = yield take(VALIDATE_OTP);
    yield* asyncValidateOtp(action);
  }
}

export function* asyncGetPlaces({ payload }) {
  const url =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    payload.place +
    "&key=" +
    AppData.MAP_API_KEY;
  // const url = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+payload.place+"&inputtype=textquery&fields=formatted_address,name,geometry&key="+AppData.MAP_API_KEY;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      noheader: true,
    });
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetPlaces() {
  while (true) {
    const action = yield take(GET_PLACES);
    yield* asyncGetPlaces(action);
  }
}

//kupos go

export function* asyncKuposGoPostTripDetails({ payload }) {
  // eslint-disable-next-line
  const { data } = payload;
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + `/api/special_trip_details`;
  // const url = `https://pbstage.kupas.cl/api/special_trip_details`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchKuposGoPostTripDetails() {
  while (true) {
    const action = yield take(KUPOS_GO_POST_TRIP_DETAILS);
    yield* asyncKuposGoPostTripDetails(action);
  }
}

// GET CUSTOMER CARDS --------- ONE CLICK CARDS -----------//'///////

export function* asyncGetCustomerCards({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/my_cards_list";

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET" });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCustomerCards() {
  while (true) {
    const action = yield take(GET_CUSTOMER_CARDS);
    yield* asyncGetCustomerCards(action);
  }
}

//Add My Cards
export function* asyncwatchAddMyCards({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/kupos_add_cards";
  // const url = "https://www.portals.org.in/api/kupos_add_cards";
  try {
    const response = yield call(App_Service, { url, method: "POST" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("AddCards", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchAddMyCards() {
  while (true) {
    const action = yield take(ADD_MY_CARDS);
    yield* asyncwatchAddMyCards(action);
  }
}

//Delete My Cards
export function* asyncwatchDeleteMyCards({ payload }) {
  // console.log("Payload of delete card api", payload)

  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/remove_kupos_card";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());

    // console.log("Delete cards api response", response);

    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchDeleteMyCards() {
  while (true) {
    const action = yield take(DELETE_MY_CARDS);
    yield* asyncwatchDeleteMyCards(action);
  }
}

// passengerInfo(card_type, id_card_number): Observable<any> {
//   return this.getUrl(this.prepareApi2('api/passenger_info?', 'id_card_type=' + card_type + '&id_card_number=' + id_card_number)).map(res => res.json());
// }

export function* asyncGetCustomerInfo({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    "api/passenger_info?id_card_type=" +
    payload.cardType +
    "&id_card_number=" +
    payload.idCardNumber;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET" });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    console.log("asyncGetCustomerInfo ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCustomerInfo() {
  while (true) {
    const action = yield take(GET_CUSTOMER_INFO);
    yield* asyncGetCustomerInfo(action);
  }
}

//GET INSTANT DISCOUNT
export function* asyncGetInstantDscount({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api_instant_discounts/apply_instant_discount";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetInstantDscount() {
  while (true) {
    const action = yield take(GET_INSTANT_DISCOUNT);
    yield* asyncGetInstantDscount(action);
  }
}

//Corporate DISCOUNT
export function* asyncGetCorporateDscount({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "api_corporate_discounts/apply_corporate_discount";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetCorporateDscount() {
  while (true) {
    const action = yield take(GET_CORPORATE_DISCOUNT);
    yield* asyncGetCorporateDscount(action);
  }
}

//getConvenios
export function* asyncGetConvenios({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/pullman/bus_bookings/pullman_convincios";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetConvenios() {
  while (true) {
    const action = yield take(GET_CONVENIOS);
    yield* asyncGetConvenios(action);
  }
}

//GetPullmanDiscount
export function* asyncGetPullmanDiscount({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    (payload.isTsService
      ? "api/pullman/bus_bookings/ts_convincio_discount"
      : "api/pullman/bus_bookings/convincio_discount");
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetPullmanDiscount() {
  while (true) {
    const action = yield take(APPLY_PULLMAN_DISCOUNT);
    yield* asyncGetPullmanDiscount(action);
  }
}

//getConvenios
export function* asyncValidateMotCoupon({ payload }) {
  console.log("Payload is---", payload);
  let data = payload.data;
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  // `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateMotCoupon() {
  while (true) {
    const action = yield take(VALIDATE_MOT);
    yield* asyncValidateMotCoupon(action);
  }
}

//getConvenios
export function* asyncValidateMotSMS({ payload }) {
  let data = payload.data;
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/services/validate_sms_code.json?code=${data.code}&mot_code=${data.motNumber}`;
  // `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateMotSMS() {
  while (true) {
    const action = yield take(VALIDATE_MOT_SMS);
    yield* asyncValidateMotSMS(action);
  }
}

//getConvenios
export function* asyncGetDirectCancelOperators({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + `/api/direct_cancel_operators`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetDirectCancelOperators() {
  while (true) {
    const action = yield take(GET_DIRECT_CANCEL_OPERATORS);
    yield* asyncGetDirectCancelOperators(action);
  }
}

//GetDirectCancelTicketDetails
export function* asyncGetDirectCancellationTicketDetails({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/services/ts_branch_can_cancel_direct";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetDirectCancellationTicketDetails() {
  while (true) {
    const action = yield take(GET_DIRECT_CANCELLATION_TICKET_DETAILS);
    yield* asyncGetDirectCancellationTicketDetails(action);
  }
}

//is cancel direct
export function* asyncIsCancelDirect({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/services/ts_branch_cancel_direct";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchIsCancelDirect() {
  while (true) {
    const action = yield take(IS_CANCEL_DIRECT);
    yield* asyncIsCancelDirect(action);
  }
}

export default function* () {
  yield all([
    fork(watchGetFacebookUserData),
    fork(watchGetApiCall),
    fork(watchPostApiCall),
    fork(watchPutApiCall),
    fork(watchDeleteApiCall),
    fork(watchLogin),
    fork(watchRegister),
    fork(watchGoogleLogout),
    fork(watchForgotPassword),
    fork(watchValidateCustomer),
    fork(watchAccountConfirmation),
    fork(watchResetNewPassword),
    fork(watchGetMetaData),
    fork(watchGetCities),
    fork(watchGetCitiesUnique),
    fork(watchGetCitiesUniqueTrain),
    fork(watchGetCityPairs),
    fork(watchGetCitiesSearch),
    fork(watchGetCustomerProfile),
    fork(watchUpdateCustomerProfile),
    fork(watchGetServices),
    fork(watchGetServicesV2),
    fork(watchGetServiceDetails),
    fork(watchGetServiceDetailsV2),
    fork(watchBookTentativeTicket),
    fork(watchGetWalletRechargeRequest),
    fork(watchGetRechargeLocations),
    fork(watchGetRecipientInfo),
    fork(watchUpdateFavourites),
    fork(watchTransferMoney),
    fork(watchRequestMoney),
    fork(watchGetTicketDetails),
    fork(watchGetTicketDetailsInt),
    fork(watchGetMyTickets),
    fork(watchAddChangeTicket),
    fork(watchGetTrainTickets),
    fork(watchGetKuposGoTickets),
    fork(watchGetKuposTicketDetails),
    fork(watchwatchGetEnvironmentThings),
    fork(watchCancelTicket),
    fork(watchGetTxnHistory),
    fork(watchGetVirtualMoney),
    fork(watchApplyPromoCode),
    fork(watchGetMarkerIcons),
    fork(watchGetQrImage),
    fork(watchGetQrInfo),
    fork(watchGetPlaces),
    fork(watchGenerateOtp),
    fork(watchValidateOtp),
    fork(watchGetDirectCancelOperators),

    //kupos go
    fork(watchKuposGoPostTripDetails),

    //ONE CLICK CARDS
    fork(watchGetCustomerCards),
    fork(watchAddMyCards),
    fork(watchDeleteMyCards),

    fork(watchGetCustomerInfo),

    //INSTANT DISCOUNT
    fork(watchGetInstantDscount),

    //CORPORATE DISCOUNT
    fork(watchGetCorporateDscount),

    fork(watchGetConvenios),
    fork(watchGetPullmanDiscount),

    //MOT
    fork(watchValidateMotCoupon),
    fork(watchValidateMotSMS),

    //DIRECT CANCELLATION
    fork(watchGetDirectCancellationTicketDetails),
    fork(watchIsCancelDirect),
  ]);
}
