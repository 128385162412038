import React, { PureComponent } from "react";

import PropTypes from "prop-types";

class PButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    secondary: PropTypes.bool,
    onPress: PropTypes.func,
    style: PropTypes.object,
    button1FontColor: PropTypes.string,
    title: PropTypes.string,
    capitalize: PropTypes.bool,
    bold: PropTypes.bool,
  };

  render() {
    return (
      <button
        className={
          "pb-button " +
          this.props.className +
          (this.props.secondary ? " secondary-button" : "") +
          (this.props.disabled ? " disbaled-button" : "")
        }
        onClick={
          this.props.onClick
            ? !this.props.disabled && this.props.onClick
            : !this.props.disabled && this.props.onPress
        }
        style={{ ...this.props.style, textTransform: "uppercase" }}
        type={this.props.type || "button"}
      >
        <div
          className={
            "pb-button-text-container " +
            this.props.button1FontColor +
            " " +
            (this.props.bold ? "bold-text" : "")
          }
        >
          {this.props.title ? (
            <span style={this.props.buttonSize}>
              {this.props.capitalize
                ? this.props.title.toUpperCase()
                : this.props.title}
            </span>
          ) : (
            this.props.children
          )}
        </div>
      </button>
    );
  }
}

export default PButton;
