import { AppData, CommonService } from '../../services';
import { IonApp, IonList } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import PBModal from '../PBModal';
import PBText from '../PBText';
import PBTouchable from '../PBTouchable';
import ServiceListItem from './ServiceListItem';

// import {
//     div,
//     FlatList,
// } from 'react-native';
// import EStyleSheet from 'react-native-extended-stylesheet';

const ServiceListItemContainer = props => {
	console.log('ServiceListItemContainer', props);
	const [stageDetails, setStageDetails] = useState();

	useEffect(() => {
		fetch(AppData.BASE_URL + 'api/stage_details')
			.then(res => res.json())
			.then(res => {
				console.log('res', res);
				setStageDetails(res.body);
			});
	}, []);

	let allRoutes = props.routes ? props.routes.concat(props.soldRoutes) : [];

	let operatorId = CommonService.getParameterByName('operator_id');
	console.log('Operator id-----', operatorId);

	return (
		<div className="list-item-container">
			{props.routes && props.routes.length ? (
				<div>
					{allRoutes.map((item, key) => {
						if (operatorId) {
							if (operatorId == item.travel_id) {
								return (
									<ServiceListItem
										key={key}
										service={item}
										amenities={props.amenities}
										onInfoButtonClick={
											props.onInfoButtonClick
										}
										stageDetails={stageDetails}
										onSerivceClicked={() =>
											props.onServiceSelect(
												item
											)
										}
									/>
								);
							}
						} else {
							return (
								<ServiceListItem
									key={key}
									service={item}
									amenities={props.amenities}
									onInfoButtonClick={
										props.onInfoButtonClick
									}
									stageDetails={stageDetails}
									onSerivceClicked={() =>
										props.onServiceSelect(item)
									}
								/>
							);
						}
					})}

					{/* {renderItem} */}
				</div>
			) : (
				<div
					style={{
						flex: 1,
						alignItems: 'center',
						justifyContent: 'center',
						padding: 20,
					}}
				>
					<PBText style={{ textAlign: 'center' }}>
						Este servicio no está disponible en la fecha que
						seleccionaste. Por favor intenta con otra fecha.
					</PBText>
				</div>
			)}
		</div>
	);
};

export default ServiceListItemContainer;
