import React from 'react';
import { dimensions } from '../theme';

// import { View, StyleSheet, Image } from 'react-native';

const PBCarpoolBG = props => {
	return props.short ? (
		<img
			src={'/assets/my-account/background-banner.png'}
			className="main-bg-short"
			style={{ height: '36.55%' }}
		/>
	) : (
		<img
			style={{ height: '100%' }}
			src={
				'/assets/images/PullmanBus-Home-MobileVersion-BlueBackground-Resource.jpg'
			}
			className="mainBgImg"
		/>
	);
};

// const styles = {
//     mainBgImg:{
//         width:dimensions.vw * 100,height:dimensions.vh*30,resizeMode:'stretch',position:'absolute',
//     }
// }

export default PBCarpoolBG;
