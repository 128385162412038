import {
  AppData,
  CommonService,
  GlobalService,
  storageService,
} from "../services";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { AppPage } from "../declarations";
import { PBText } from "../components";
import React from "react";
import { connectData } from "../redux";
import { menuController } from "@ionic/core";

var global: any = window;

interface MenuProps extends RouteComponentProps {
  appPages: AppPage[];
  setCitiesUnique: Function;
  setCitiesUniqueTrain: Function;
  loginSuccess: Function;
  getMetaDataSuccess: Function;
  getCitiesUnique: Function;
  getCitiesUniqueTrain: Function;
  getMetaData: Function;
  data: any;
  showHideContactModal: any;
  setThirdPartyApp: Function;
}

class Menu extends React.Component<MenuProps> {
  state: any;
  constructor(props: any) {
    super(props);
    this.state = {
      showContactModal: false,
    };
    let referalApp = CommonService.idThirdPartyApp();
    if (referalApp) {
      CommonService.setReferralCode(
        CommonService.getParameterByName("referral_code"),
      );
      this.props.setThirdPartyApp(referalApp);
    } else {
      if (window.location.href.indexOf("kupas.cl") <= -1) {
        // window.location.href = "https://www.pasajebus.com"
      }
    }
  }

  componentDidMount() {
    CommonService.windowHeight = window.innerHeight;
    this.checkLogin();
  }

  checkLogin = () => {
    let citiesUnique = localStorage.getItem("citiesUnique");
    // let citiesUniqueTrain = localStorage.getItem("citiesUnique");
    if (citiesUnique) {
      let citiesUniqueJ = JSON.parse(citiesUnique);
      this.props.setCitiesUnique(citiesUniqueJ);
    }
    let citiesUniqueTrain = localStorage.getItem("citiesUniqueTrain");
    if (citiesUniqueTrain) {
      let citiesUniqueTrainJ = JSON.parse(citiesUniqueTrain);
      this.props.setCitiesUniqueTrain(citiesUniqueTrainJ);
    }

    let metaData = storageService.getItem("metaData");
    if (metaData) {
      this.props.getMetaDataSuccess(JSON.parse(metaData));
    }

    let loginData = storageService.getItem("loginData");
    if (loginData && Object.keys(loginData).length) {
      let loginJson = JSON.parse(loginData);
      // this.props.loginSuccess(loginJson);
      // // GlobalService.setGlobal('token',loginData.auth_token);
      global.token = loginJson.auth_token;
      CommonService.storeLoginDetails(loginJson, this.props.loginSuccess);
    } else {
      // GlobalService.setGlobal('token',null);
      global.token = null;
    }
    const { getMetaData, getCitiesUnique } = this.props;
    getMetaData({
      callback: () => {
        setTimeout(() => {
          localStorage.setItem(
            "metaData",
            JSON.stringify(this.props.data.metaData),
          );
        }, 400);
      },
      metaData: metaData,
    });
    getCitiesUnique({
      callback: (result: any) => {
        this.props.setCitiesUnique(result.cities);
        // console.log("result.cities", result.cities);
        setTimeout(() => {
          localStorage.setItem("citiesUnique", JSON.stringify(result.cities));
        }, 300);
      },
      cities: citiesUnique,
    });
  };

  editProfile = () => {
    this.props.history.push("/modify-profile");
    menuController.close();
  };
  goToLogin = () => {
    this.props.history.push("/login-options");
    menuController.close();
  };

  render() {
    const { appPages, showHideContactModal } = this.props;
    const allowedPagesForGuests = ["2", "12", "18", "4", "20", "9", "16"];
    // console.log("Menu Items",appPages)
    return (
      <IonMenu
        disabled={this.props.data.thirdPartyApp}
        contentId="main"
        type="overlay"
        className="split-menu-custom"
      >
        <IonContent>
          <div className="menu-container-div">
            <div className="welcome-container font10">
              {CommonService.isLoggedIn(this.props.data.loginData) ? (
                <div className="welcome-image-container">
                  {this.props.data.loginData.image_link ? (
                    <div
                      className="welcome-image-circle"
                      style={{
                        backgroundImage: `url(${this.props.data.loginData.image_link ||
                          "/assets/carpool/camera-add-circle.png"
                          })`,
                      }}
                    >
                      {/* <img alt='profile-pic' src={this.props.data.loginData.image_link} /> */}
                    </div>
                  ) : (
                    <div className="camera-add-circle home-icon-circular-sprite size55"></div>
                  )}
                  <div className="user-name-container">
                    <div className="display-flex flex-wrap">
                      <PBText
                        fontColor={"dark-font"}
                        fontStyle={"bold-font"}
                        fontSize={"font16"}
                      >
                        ¡Hola&nbsp;
                      </PBText>
                      <PBText dark={true} bold={true} fontSize={"font16"}>
                        {this.props.data.loginData.name}!
                      </PBText>
                    </div>

                    {this.props.data.loginData ? (
                      <div className="display-flex" onClick={this.editProfile}>
                        <PBText fontSize={"font13"}>Modifica tu perfil</PBText>
                        <img
                          height={16}
                          width={16}
                          style={{
                            marginLeft: 6,
                          }}
                          src={"../assets/carpool/sidemenu/pencil.png"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="welcome-image-container">
                  <div
                    onClick={this.goToLogin}
                    className="camera-add-circle home-icon-circular-sprite size55"
                  ></div>
                  <div className="user-name-container">
                    <div className="display-flex">
                      <PBText
                        fontColor={"dark-font"}
                        fontStyle={"bold-font"}
                        fontSize={"font16"}
                      >
                        ¡Hola!
                      </PBText>
                    </div>
                  </div>
                </div>
              )}

              {/* { 
                CommonService.isLoggedIn(props.loginData) 
                ?<View style={styles.welcomeTextContainer}><PBText style={styles.welcomeText}>{props.welcomeText}</PBText></View>
                :null
            } */}
            </div>

            {/* <div>
                {
                  <div className="welcome-image-container">
                    <img className="welcome-image" height={100} src={'../assets/images/pb_logo_circle.png'} />
                  </div>
                }
              </div> 
            */}

            {/* <div className="pink-box"></div> */}


            <IonList>
              {appPages
                .filter((appPage: any) => {
                  const isLoggedIn = this.props.data.loginData;
                  return isLoggedIn || allowedPagesForGuests.includes(appPage.id);
                })
                .map((appPage: any, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      lines="none"
                      className="side-menu-item"
                      routerLink={
                        !this.props.data.loginData && ["2", "12", "18"].includes(appPage.id)
                          ? "/login-options" 
                          : `/${appPage.url.replace(/^\/+/, '')}`
                      }
                      routerDirection="none"
                      target={appPage.newTabLink ? "_blank" : ""}
                      onClick={() => {
                        if (appPage.id === "9") {
                          showHideContactModal(true); 
                        } else if (appPage.newTabLink) {
                          window.location.href = appPage.url; 
                        }
                      }}
                    >
                      {["30", "16"].includes(appPage.id) ? (
                        <img src={appPage.src} alt="" />
                      ) : (
                        <div className={"home-icon-sprite " + appPage.icon}></div>
                      )}
                      <IonLabel className="side-menu-label font15">
                        {appPage.title}
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                ))}
            </IonList>


          </div>
        </IonContent>
      </IonMenu>
    );
  }
}

export default connectData()(withRouter(Menu));
