import React from 'react'
import { menuController } from "@ionic/core";
// import { View, StyleSheet } from 'react-native';
// import Image from 'react-native-scalable-image';

import PBText from './PBText';
import { CommonService } from '../services';
import { dimensions } from '../theme';
import PBTouchable from './PBTouchable';

// const openDrawer = ()=>{
//   menuController.open;
// }

const CarpoolHeader = ({ menuIcon, showHomeIcon, whiteHomeIcon, backIcon, whiteBackIcon, backIconColor, leftIcon, rightIcon, label, labelBold, labelDark, onLefIconPress, onRightIconPress, componentId, leftIconColor, rightIconColor, leftIconHeight, rightIconHeight, history }) => {
  return (
    <div className={"carpol-header " + (whiteBackIcon ? '' : 'white-bg')}>
      {menuIcon ? (
        <div className="carpol-homeMenu">
          <PBTouchable
          // onClick={CommonService.openCloserMenu}
          >
            <div
              onClick={() => menuController.open()}
              className="carpol-homeMenuInner"
            >
              <img
                style={{ tintColor: leftIconColor }}
                // height={dimensions.vw * 7}
                src={"/assets/carpool/menu-dot.png"}
              />
            </div>
          </PBTouchable>
        </div>
      ) : null}

      {backIcon ? (
        <div className="carpol-homeMenu">
          <PBTouchable onPress={() => history.goBack()}>
            <div className="carpol-homeMenuInner">
              <img
                // height={dimensions.vw * 4.5}
                src={whiteBackIcon ? '/assets/carpool/icon_arrow_back_white.png':"/assets/carpool/icon_back_arrow.png"}
                style={{ tintColor: backIconColor }}
              />
            </div>
          </PBTouchable>
        </div>
      ) : null}
      {leftIcon ? (
        <div className="carpol-homeMenu">
          <PBTouchable onClick={onLefIconPress}>
            <div className="carpol-homeMenuInner">
              <img
                style={{ tintColor: leftIconColor }}
                // height={leftIconHeight ? leftIconHeight : dimensions.vw * 7}
                src={leftIcon}
              />
            </div>
          </PBTouchable>
        </div>
      ) : null}
      {label ? (
        <div className="flex-row font19 label-center">
          <div
            style={{ color: labelDark ? "#464647" : "#fff" }}
          >
            {label}&nbsp;
          </div>
          {labelBold ? (
            <PBText
              // fontStyle={"black-text"}
              bold={true}
              style={{ color: labelDark ? "#464647" : "#fff" }}
            >
              {labelBold}
            </PBText>
          ) : null}
        </div>
      ) : null}

      {rightIcon ? (
        <div className="carpol-homeMenu">
          <PBTouchable onClick={onRightIconPress}>
            <div className="carpol-homeMenuInner">
              <img
                style={{ tintColor: rightIconColor }}
                // height={rightIconHeight ? rightIconHeight : dimensions.vw * 7}
                src={rightIcon}
              />
            </div>
          </PBTouchable>
        </div>
      ) : null}

      
    {showHomeIcon ? (
        <div className="carpol-homeMenu">
          <a href="/">
            <div className="carpol-menu-home-icon">
              <img
                // height={rightIconHeight ? rightIconHeight : dimensions.vw * 7}
                src='/assets/carpool/home.svg'
                style={{filter:(whiteHomeIcon ? 'invert(1)' : 'invert(0)')}}
              />
            </div>
          </a>
        </div>
      ) : (!rightIcon ? <div style={{width:45}}></div> : null)}
    </div>
  );
}

export default CarpoolHeader;