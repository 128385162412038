import React, { useState } from "react";
import { colors, dimensions } from "../../theme";

import { CommonService } from "../../services";
import PBModal from "../PBModal";
import PBText from "../PBText";
import PBTouchable from "../PBTouchable";

const getSeatIcon = (seat, petSeats) => {
  try {
    if (petSeats && petSeats[seat.number]) {
      if (
        !seat.isGangWay &&
        seat.isReservable &&
        seat.number &&
        !seat.isSelected
      ) {
        return "/assets/icons/seats/pet-seat_available.png";
      } else if (!seat.isGangWay && seat.isReservable && seat.isSelected) {
        return "/assets/icons/seats/pet-seat_selected.png";
      } else if (!seat.isGangWay && !seat.isReservable) {
        return "/assets/icons/seats/pet-seat_occupied.png";
      }
    }
    if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.number &&
      !seat.isSelected &&
      seat.number &&
      (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
        seat.type.toUpperCase().indexOf("CLASICO") > -1)
    ) {
      return "/assets/icons/seats/icon-executive-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.number &&
      (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
        seat.type.toUpperCase().indexOf("CLASICO") > -1)
    ) {
      return "/assets/icons/seats/icon-executive-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.number &&
      (seat.type.toUpperCase().indexOf("EJECUTIVO") > -1 ||
        seat.type.toUpperCase().indexOf("CLASICO") > -1)
    ) {
      return "/assets/icons/seats/icon-executive-seat_occupied.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      !seat.isSelected &&
      seat.type.toUpperCase() == "PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.type.toUpperCase() == "PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.type.toUpperCase() == "PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_occupied.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      !seat.isSelected &&
      seat.type.toUpperCase().indexOf("SALON CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.type.toUpperCase().indexOf("SALON CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.type.toUpperCase().indexOf("SALON CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_occupied.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      !seat.isSelected &&
      seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-semi-bed-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-semi-bed-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.type.toUpperCase().indexOf("SEMI CAMA") > -1
    ) {
      return "/assets/icons/seats/icon-semi-bed-seat_occupied.png";
    } else if (seat.number == "DR_IMG") {
      return "/assets/icons/seats/icon-steering.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      !seat.isSelected &&
      seat.type.toUpperCase() == "CAMA PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.type.toUpperCase() == "CAMA PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.type.toUpperCase() == "CAMA PREMIUM"
    ) {
      return "/assets/icons/seats/icon-vip-seat_occupied.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      !seat.isSelected &&
      seat.type.toUpperCase().indexOf("BIO BIO SUPERIOR") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_available.png";
    } else if (
      !seat.isGangWay &&
      seat.isReservable &&
      seat.isSelected &&
      seat.type.toUpperCase().indexOf("BIO BIO SUPERIOR") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_selected.png";
    } else if (
      !seat.isGangWay &&
      !seat.isReservable &&
      seat.type.toUpperCase().indexOf("BIO BIO SUPERIOR") > -1
    ) {
      return "/assets/icons/seats/icon-salon-cama-seat_occupied.png";
    } else if (!seat.isGangWay && seat.isReservable && !seat.isSelected) {
      return "/assets/icons/seats/icon-salon-cama-seat_available.png";
    } else if (!seat.isGangWay && seat.isReservable && seat.isSelected) {
      return "/assets/icons/seats/icon-salon-cama-seat_selected.png";
    } else if (!seat.isGangWay && !seat.isReservable) {
      return "/assets/icons/seats/icon-salon-cama-seat_occupied.png";
    }
  } catch (e) {
    console.log(e);
  }
};

const SeatLayout = (props) => {
  const {
    coachLayout,
    onFloorChange,
    selectedFloor,
    metaData,
    selectedSeatsToBook,
    upperAvalibility,
    selectedService,
    data,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [alreadyShowModal, setAlreadyShowModal] = useState(false);
//   const [showMotMaxSeatModal, setShowMotMaxSeatModal] = useState(false);

  const selectPetSeat = (
    seat,
    seat2,
    metaData,
    coachLayout,
    selectedSeatsToBook,
    selectedService,
    data
  ) => {
    try {
      if (!seat.object.available) {
        return;
      }
      // CommonService.googleProductClick(
      // 	CommonService.convertBusServiceToGoogleProduct(
      // 		seat.object,
      // 		selectedService,
      // 		'bus'
      // 	)
      // );
      let bookingDetails = {};
      let currentSeat = seat;
      let currentSeat2 = seat2;
      let selectedSeatsNumbers = "";
      selectedSeatsToBook = JSON.parse(
        JSON.stringify(selectedSeatsToBook ? selectedSeatsToBook : [])
      );
      let currentSeatJson =
        coachLayout[currentSeat["object"].row_id][currentSeat["object"].col_id];
      let currentSeatJson2 =
        coachLayout[currentSeat2["object"].row_id][
          currentSeat2["object"].col_id
        ];

      if (
        selectedSeatsToBook.length <
          ((data &&
            data.serviceDetails.res_details &&
            data.serviceDetails.res_details.no_of_seats_to_be_booked) ||
            metaData.max_seats_allowed) ||
        currentSeatJson.isSelected
      ) {
        currentSeatJson.isSelected = !currentSeatJson.isSelected;
        currentSeatJson2.isSelected = !currentSeatJson2.isSelected;
        seat.object.is_pet_seat = true;
        seat.object.associated_seat = seat2.object.number;
        seat.object.pet_seat_type = "primary";
        seat2.object.pet_seat_type = "secondary";
        if (currentSeatJson.isSelected) {
          selectedSeatsToBook.push(seat.object);
          selectedSeatsToBook.push(seat2.object);
        } else {
          selectedSeatsToBook.splice(
            selectedSeatsToBook.findIndex((item) => {
              if (item.number === seat.object.number) {
                item.passengerName = "";
                item.age = "";
              }
              return item.number === seat.object.number;
            }),
            1
          );
          selectedSeatsToBook.splice(
            selectedSeatsToBook.findIndex((item) => {
              if (item.number === seat2.object.number) {
                item.passengerName = "";
                item.age = "";
              }
              return item.number === seat2.object.number;
            }),
            1
          );
        }
        let totalBookingAmount = 0;
        let oTotalBookingAmount = 0;
        let selectedSeats = [];
        let selectedSeatTypes = {};
        for (var i = 0; i < selectedSeatsToBook.length; i++) {
          totalBookingAmount += +selectedSeatsToBook[i].fare;
          oTotalBookingAmount += +selectedSeatsToBook[i].o_fare;
          selectedSeats.push(
            selectedSeatsToBook[i].floor_no +
              1 +
              "_" +
              selectedSeatsToBook[i].number
          );
          if (!selectedSeatTypes[selectedSeatsToBook[i].type]) {
            selectedSeatTypes[selectedSeatsToBook[i].type] = [];
          }
          selectedSeatTypes[selectedSeatsToBook[i].type].push(
            selectedSeatsToBook[i]
          );
        }
        for (let key in selectedSeatTypes) {
          let seatNumbers = [];
          for (let type of selectedSeatTypes[key]) {
            seatNumbers.push(type.number);
          }
          selectedSeatTypes[key][0]["seatNumbers"] = seatNumbers.join(",");
        }
        selectedSeatsNumbers = selectedSeats.join(",");
        // discountAmount = this.commonService.calculateAmountByPercentage(totalBookingAmount,metaData.discountPercentage);

        let discountAmount = 0;
        let netBookingAmount = totalBookingAmount - discountAmount;

        // -------------------- Fare charges details --------------------
        bookingDetails.totalBookingAmount = totalBookingAmount;
        bookingDetails.oTotalBookingAmount = oTotalBookingAmount;
        bookingDetails.netBookingAmount = netBookingAmount;
        bookingDetails.discountAmount = discountAmount;
        bookingDetails.selectedSeatsToBook = selectedSeatsToBook;
        bookingDetails.selectedSeatsNumbers = selectedSeatsNumbers;
        bookingDetails.selectedSeatTypes = selectedSeatTypes;
        return bookingDetails;
      } else {
        return "MAX_SEATS_ERROR";
        // console.log('Maximum Number of Seat Selection is reached');
        // this.util.showToast(this.util.tranlateInstant("max_four_seats_allowed"));
      }
    } catch (e) {
      console.log("SelectseatsController_selectSeatFunction", e);
    }
  };

  const selectSeat = (
    seat,
    metaData,
    coachLayout,
    selectedSeatsToBook,
    selectedService,
    data
  ) => {
    try {
      if (!seat.object.available) {
        return;
      }

      let is_mot = sessionStorage.getItem("is_mot");

      if (is_mot === "true") {
        is_mot = true;
      }

      let selectedSeats = []; //todo

      if (selectedService && selectedService.route) {
        selectedSeats.push({
          // id: Number(seat.number),
          name: `${CommonService.isTrain() ? "train" : "bus"}-${
            data.selectedOrigin.full_name
          } to ${data.selectedDestination.full_name}`,
          price: Number(seat.object.fare),
          brand: selectedService.route.operator_service_name,
          category: CommonService.isTrain() ? "train" : "bus",
          variant: selectedService.route.dep_time,
          position: seat.object.row_id,
          quantity: selectedSeatsToBook ? selectedSeatsToBook.length + 1 : 1,
        });
        // alert(selectedSeatsToBook.length)
        if (is_mot && selectedSeats && selectedSeats[0]?.quantity > 1) {
          // alert("mot seat selected");
        //   setShowMotMaxSeatModal(true);
          // return "only_1_seat";
          return "MAX_SEATS_ERROR_MOT";
        }

        CommonService.pushSelectedSeats(selectedSeats);
      }
      // CommonService.googleProductClick(
      // 	CommonService.convertBusServiceToGoogleProduct(
      // 		seat.object,
      // 		selectedService,
      // 		'bus'
      // 	)
      // );
      let bookingDetails = {};
      let currentSeat = seat;
      let selectedSeatsNumbers = "";
      selectedSeatsToBook = JSON.parse(
        JSON.stringify(selectedSeatsToBook ? selectedSeatsToBook : [])
      );
      let currentSeatJson =
        coachLayout[currentSeat["object"].row_id][currentSeat["object"].col_id];

      let confirmTicketData = sessionStorage.getItem("confirmTicketData");

      if (
        (selectedSeatsToBook.length <
          ((data &&
            data.serviceDetails.res_details &&
            data.serviceDetails.res_details.no_of_seats_to_be_booked) ||
            metaData.max_seats_allowed) &&
          (!confirmTicketData || selectedSeatsToBook.length < 1)) ||
        currentSeatJson.isSelected
      ) {
        currentSeatJson.isSelected = !currentSeatJson.isSelected;
        if (currentSeatJson.isSelected) {
          selectedSeatsToBook.push(seat.object);
        } else {
          selectedSeatsToBook.splice(
            selectedSeatsToBook.findIndex((item) => {
              if (item.number === seat.object.number) {
                item.passengerName = "";
                item.age = "";
              }
              return item.number === seat.object.number;
            }),
            1
          );
        }
        let totalBookingAmount = 0;
        let oTotalBookingAmount = 0;
        let selectedSeats = [];
        let selectedSeatTypes = {};
        for (var i = 0; i < selectedSeatsToBook.length; i++) {
          totalBookingAmount += +selectedSeatsToBook[i].fare;
          oTotalBookingAmount += +selectedSeatsToBook[i].o_fare;
          selectedSeats.push(
            selectedSeatsToBook[i].floor_no +
              1 +
              "_" +
              selectedSeatsToBook[i].number
          );
          if (!selectedSeatTypes[selectedSeatsToBook[i].type]) {
            selectedSeatTypes[selectedSeatsToBook[i].type] = [];
          }
          selectedSeatTypes[selectedSeatsToBook[i].type].push(
            selectedSeatsToBook[i]
          );
        }
        for (let key in selectedSeatTypes) {
          let seatNumbers = [];
          for (let type of selectedSeatTypes[key]) {
            seatNumbers.push(type.number);
          }
          selectedSeatTypes[key][0]["seatNumbers"] = seatNumbers.join(",");
        }
        selectedSeatsNumbers = selectedSeats.join(",");
        // discountAmount = this.commonService.calculateAmountByPercentage(totalBookingAmount,metaData.discountPercentage);

        let discountAmount = 0;
        let netBookingAmount = totalBookingAmount - discountAmount;

        // -------------------- Fare charges details --------------------
        bookingDetails.totalBookingAmount = totalBookingAmount;
        bookingDetails.oTotalBookingAmount = oTotalBookingAmount;
        bookingDetails.netBookingAmount = netBookingAmount;
        bookingDetails.discountAmount = discountAmount;
        bookingDetails.selectedSeatsToBook = selectedSeatsToBook;
        bookingDetails.selectedSeatsNumbers = selectedSeatsNumbers;
        bookingDetails.selectedSeatTypes = selectedSeatTypes;
        return bookingDetails;
      } else {
        return "MAX_SEATS_ERROR";
        // this.util.showToast(this.util.tranlateInstant("max_four_seats_allowed"));
      }
    } catch (e) {
      console.log("SelectseatsController_selectSeatFunction", e);
    }
  };

  let floorCoachLayout = coachLayout[selectedFloor];
  const isPetSeatPair = (seat) => {
    let petSeats = null;

    if (
      selectedService &&
      selectedService.route &&
      selectedService.route.pet_seat_info
    ) {
      petSeats = selectedService.route.pet_seat_info;
    } else if (
      selectedService &&
      selectedService.route &&
      selectedService.route.res_details
    ) {
      petSeats = selectedService.route.res_details.pet_seat_info;
    }
    if (petSeats) {
      //   let petSeats = selectedService.route.res_details.pet_seat_info;
      for (let p in petSeats) {
        let sNo = petSeats[p].find((it) => it == seat.number);
        if (sNo) {
          let seats = [];
          for (let row of coachLayout) {
            if (!seats[0])
              seats[0] = row.find((it) => it.number == petSeats[p][0]);
            if (!seats[1])
              seats[1] = row.find((it) => it.number == petSeats[p][1]);
          }
          return seats;
        }
      }
    }
    return null;
  };

  let seatRow = () => {
    let rows = coachLayout.map((row, i) => (
      <div
        key={i}
        className="seat-row"
        // style={styles.seatRow}
      >
        {seatCol(row)}
      </div>
    ));
    return rows;
  };

  let seatCol = (row) => {
    let cols = row.map((seat, i) => {
      // if(selectedWagon == i){
      return ((seat &&
        !(
          seat.isGangWay ||
          seat.isBreak ||
          (seat.number &&
            (seat.number.indexOf("GY") > -1 || seat.number.indexOf("gy") > -1))
        )) ||
        seat.number == "DR_IMG") &&
        seat.object.floor_no === selectedFloor ? (
        seatItem(seat, i)
      ) : (
        <div className="train-seat-gangway" key={i} />
      );
    });
    return cols;
  };

  let seatItem = (seat, i) => {
    let petSeats = null;

    if (
      selectedService &&
      selectedService.route &&
      selectedService.route.pet_seat_info
    ) {
      petSeats = selectedService.route.pet_seat_info;
    } else if (
      selectedService &&
      selectedService.route &&
      selectedService.route.res_details
    ) {
      petSeats = selectedService.route.res_details.pet_seat_info;
    }
    let isPetSeat = false;
    if (petSeats && petSeats[seat.number]) {
      isPetSeat = true;
    }
    return (
      <div
        className="seat-layout-inner-container"
        key={i}
        onClick={() => {
          let petPair = isPetSeatPair(seat);
          let confirmTicketData = sessionStorage.getItem("confirmTicketData");
          if (petPair) {
            if (confirmTicketData) return;
            if (!seat.isSelected)
              setShowModal({
                showModal: true,
                modalTitle: "",
                //   modalBody: "Pet seat Popup",
                showButton1: true,
                buttonText1: "RESULTS_PAGE.CONTINUE",
              });

            props.onSeatTap(
              selectPetSeat(
                petPair[0],
                petPair[1],
                metaData,
                coachLayout,
                selectedSeatsToBook,
                selectedService,
                data
              )
            );
            // if (!alreadyShowModal) {
            // 	setShowModal({
            // 		showModal: true,
            // 		modalTitle: '',
            // 		// modalBody: 'Pet seat Popup',
            // 		showButton1: true,
            // 		buttonText1:
            // 			'RESULTS_PAGE.CONTINUE',
            // 	});
            // 	setAlreadyShowModal(true);
            // }
          } else {
            props.onSeatTap(
              selectSeat(
                seat,
                metaData,
                coachLayout,
                selectedSeatsToBook,
                selectedService,
                data
              )
            );
          }
        }}
      >
        <div className="seat-column">
          {getSeatIcon(seat, petSeats) ? (
            <img
              // style={styles.seatTypeIcon}
              style={{
                width: isPetSeat
                  ? 24
                  : seat.number == "DR_IMG"
                  ? 25
                  : seat.type == "SEMI CAMA" ||
                    seat.type == "CLASICO" ||
                    seat.type == "Clasico"
                  ? 28
                  : seat.type == "SALON CAMA" ||
                    seat.type == "SALON CAMA P" ||
                    seat.type == "SALON CAMA IND"
                  ? 38
                  : seat.type == "Ejecutivo"
                  ? 28
                  : seat.type == "Premium"
                  ? 35
                  : seat.type == "CAMA PREMIUM"
                  ? 35
                  : 26,
                position: "relative",
                top: isPetSeat ? 0 : 0,
                margin: isPetSeat ? "0 7px" : 0,
              }}
              className={
                seat.number == "DR_IMG" ? "steering-img driver-seat-icon" : ""
              }
              src={getSeatIcon(seat, petSeats)}
              alt="some"
            />
          ) : null}
          <div
            className="seat-number"
            style={seat.type == "SALON CAMA" ? { top: "-1%" } : {}}
          >
            <PBText
              fontSize={"font10"}
              textAlign={"text-center"}
              fontColor={
                seat.isSelected
                  ? "white-text"
                  : seat.isReservable
                  ? "primary-font"
                  : "dark-font"
              }
              style={{ marginTop: isPetSeat ? 19 : 0 }}
              //  style={{ fontSize: 9, textAlign: "center" }}
            >
              {seat.number != "DR_IMG" ? seat.number : ""}
            </PBText>
          </div>
        </div>
      </div>
    );
  };

  let isSeatType = (type) => {
    // for (let fLayout of coachLayout) {
    for (let row of coachLayout) {
      for (let seat of row) {
        if (!seat) {
          return false;
        }
        if (
          seat.type.toUpperCase() == type &&
          seat.number.toUpperCase().indexOf("GY") < 0
        ) {
          return true;
        }
      }
    }
    // }
    return false;
  };

  let getSeatType = (text, icon) => {
    return (
      <div
        className="seat-type-item"
        // style={styles.seatTypeItem}
      >
        <img className="seat-type-show-image" src={icon} />
        <span
          className={"font13 dark-font"}
          // style={styles.seatTypeText}
        >
          {text}
        </span>
      </div>
    );
  };

  let getTabs = () => {
    let tabs = [];
    for (let i = 0; i < 2; i++) {
      tabs.push(
        <PBTouchable
          key={i}
          className={
            // selectedFloor == "upper"
            "tab-item-outer " + (selectedFloor === i ? "active black-text" : "")
          }
          onPress={() => onFloorChange(i)}
        >
          <div className="tab-item train-tab-item">
            <PBText fontSize={"font11"} fontColor={"white-font"}>
              Piso #{i + 1}
            </PBText>
          </div>
        </PBTouchable>
      );
    }
    return tabs;
  };

  const hasPetSeats = () => {
    let petSeats = null;

    if (
      selectedService &&
      selectedService.route &&
      selectedService.route.pet_seat_info
    ) {
      petSeats = selectedService.route.pet_seat_info;
    } else if (
      selectedService &&
      selectedService.route &&
      selectedService.route.res_details
    ) {
      petSeats = selectedService.route.res_details.pet_seat_info;
    }

    if (petSeats) {
      if (Object.keys(petSeats).length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="seat-layout-container">
      <PBModal
        showModal={showModal.showModal}
        title={showModal.modalTitle}
        bodyText={showModal.modalBody}
        type="alert"
        showButton1={true}
        button1Text={"OK, CONTINUAR "}
        button1Press={() => setShowModal({})}
        showButton2={false}
        buttonTextStyle={{ fontSize: 15 }}
        onHide={() => setShowModal({})}
        showClose={true}
      >
        <div className="pet-modal">
          <div className="pet-modal-icon ">
            <img src="/assets/svgs/pet-modal-icon.svg" width="160" />
          </div>
          <div className="pet-modal-title bold-text font16">
            <span>¡Información importante!</span>
            <div className="pet-modal-title-underline "></div>
          </div>
          <div className="pet-modal-subtitle bold-text font13">
            Seleccionaste un asiento dentro del espacio destinado a mascota a
            bordo.
          </div>
          <div className="pet-modal-list">
            <div className="pet-modal-list-item font12">
              <span className="pet-modal-list-dot"></span>
              <span>
                Solo pueden viajar perros y gatos en un canil que no exceda los
                60 cm de largo, 34 cm de alto y 38,5 cm de ancho.
              </span>
            </div>
            <div className="pet-modal-list-item font12">
              <span className="pet-modal-list-dot"></span>
              <span>Solo puede viajar una mascota por pasajero.</span>
            </div>
            <div className="pet-modal-list-item font12">
              <span className="pet-modal-list-dot"></span>
              <span>
                Se debe firmar una “Declaración Jurada de Tenencia Responsable”
                antes del viaje, la cual se enviará junto al pasaje.
              </span>
            </div>
          </div>
        </div>
      </PBModal>
      <div className="seat-details">
        <div className="seat-types">
          {isSeatType("EJECUTIVO") || isSeatType("CLASICO")
            ? getSeatType(
                "Clásico",
                "/assets/icons/seats/icon-seat-legend_executive.png"
              )
            : null}
          {isSeatType("SEMI CAMA")
            ? getSeatType(
                "Semi Cama",
                "/assets/icons/seats/icon-seat-legend_semi-bed.png"
              )
            : null}
          {isSeatType("PREMIUM")
            ? getSeatType(
                "Premium",
                "/assets/icons/seats/icon-seat-legend_vip.png"
              )
            : null}
          {isSeatType("SALON CAMA")
            ? getSeatType(
                "Salón Cama",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}

          {isSeatType("SALON CAMA P")
            ? getSeatType(
                "Salón Cama P",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}
          {isSeatType("SALON CAMA IND")
            ? getSeatType(
                "Salón Cama IND",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}
          {isSeatType("SALON SIN VENT")
            ? getSeatType(
                "Salón sin ventana",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}

          {isSeatType("CAMA PREMIUM")
            ? getSeatType(
                "Cama Premium",
                "/assets/icons/seats/icon-seat-legend_vip.png"
              )
            : null}

          {isSeatType("BIO BIO SUPERIOR")
            ? getSeatType(
                "Bio Bio Superior",
                "/assets/icons/seats/icon-seat-legend_salon-cama.png"
              )
            : null}

          {hasPetSeats()
            ? getSeatType(
                "Mascota a bordo",
                "/assets/icons/seats/icon-seat-legend_pet.png"
              )
            : null}
        </div>

        <div className="legend-row-container">
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#ffd18f",
                borderColor: "#fa5e00",
              }}
            ></div>
            <span className={"dark-font font14"} style={styles.legendText}>
              Disponible
            </span>
          </div>
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#003ba7",
                borderColor: "#003ba7",
                // borderColor: "#ff7f45",
              }}
            ></div>
            <span className={"dark-font font14"}>Seleccionado</span>
          </div>
          <div className="legend-row">
            <div
              className="legend-circle"
              style={{
                backgroundColor: "#dbdbdb",
                borderColor: "#969494",
              }}
            ></div>
            <span className={"dark-font font14"} style={styles.legendText}>
              Reservado
            </span>
          </div>
        </div>
      </div>

      <div className="floor-and-seats-section">
        {upperAvalibility ? (
          <div className="floor-tabs-container train-floor-tabs-container">
            <div className="floor-tabs">{getTabs()}</div>
          </div>
        ) : null}

        <div
          className="seats-section-container"
          style={{
            paddingTop: upperAvalibility ? "10px" : 0,
            marginTop: upperAvalibility ? "17px" : 0,
          }}
        >
          <div className="seat-layout-inner">
            <div
            // contentContainerStyle={styles.contentContainer}
            >
              {seatRow()}
            </div>
          </div>
        </div>
      </div>

      {/* <PBModal
        showModal={showMotMaxSeatModal}
        titleText={
          "El uso de cupones está permitido para un solo asiento, por favor seleccione solo un asiento"
        }
        type="alert"
        showButton1={true}
        button1Text={"OK"}
        button1Press={() => setShowMotMaxSeatModal(false)}
        showButton2={false}
        buttonTextStyle={{ fontSize: 15 }}
        onHide={() => setShowMotMaxSeatModal(false)}
        showClose={true}
      ></PBModal> */}
    </div>
  );
};

const styles = {
  //seat Layout container
  seatTypeItem: {
    alignItems: "center",
    marginBottom: 10,
  },
  seatTypeText: {
    fontSize: 13,
  },
  floorTabs: {
    flexDirection: "row",
    width: "100%",
  },
  tabItem: {
    padding: 15,
    paddingTop: 4,
    paddingBottom: 4,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "#ccc",
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  seatLayoutContainer: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    paddingTop: 13,
  },
  seatDetails: {
    width: "45%",
    height: "100%",
    padding: 10,
  },
  seatTypes: {
    flex: 1,
  },
  seatLegendContainer: {},
  legendRow: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  legendCircle: {
    width: 15,
    height: 15,
    backgroundColor: "#ccc",
    borderRadius: 15,
    borderWidth: 1,
    marginRight: 5,
  },

  seatLayout: {
    width: "55%",
    height: "120%",
  },
  layoutInner: {
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
    padding: 10,
    flex: 1,
  },
  seatRow: {
    flexDirection: "row",
  },
  seatColumn: {
    padding: 10,
    alignItems: "center",
  },
  gangway: {
    width: 16,
  },
  seatNumber: {
    position: "absolute",
    top: "50%",
    marginTop: -5,
    width: "100%",
    alignItems: "center",
  },

  // ----
};

export default SeatLayout;
