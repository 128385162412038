import { CommonService } from "../../services";

const seatIdentifiers = {
  ".GY": "Gangway",
  ".DR_IMG": "Driver Image",
  ".ST_IMG": "Strairs Image",
  ".WR_IMG": "Wash Room Image",
  ".BR": "Break",
  GY: "Gangway",
  DR_IMG: "Driver Image",
  ST_IMG: "Strairs Image",
  WR_IMG: "Wash Room Image",
  BR: "Break",
  SS: "Semi Sleeper",
  LB: "Lower Berth",
  UB: "Upper Berth",
  BS: "CLASICO",
  PB: "CLASICO",
  NPB: "CLASICO",
  SLB: "CLASICO",
  SUB: "CLASICO",
  SST: "CLASICO",
  NA: "CLASICO",
  ST: "CLASICO",
  DLB: "CLASICO",
  DUB: "CLASICO",
  WSS: "CLASICO",
  WST: "CLASICO",
  WLB: "CLASICO",
  WUB: "CLASICO",
  WSL: "CLASICO",
  WSU: "CLASICOr",
  BU: "CLASICO",
  EC: "Semi Cama",
  "SEMI CAMA": "SEMI CAMA",
  SEMI_CAMA: "SEMI CAMA",
  "SALON CAMA": "SALON CAMA",
  SALONCAMA: "SALON CAMA",
  SALON_CAMA: "SALON CAMA",
  CLASICO: "Clasico",
  EJECUTIVO: "Ejecutivo",
  PREMIUM: "Premium",
  Premium: "Premium",
  SC: "Semi Cama",
  CO: "CLASICO",
  EX: "SALON CAMA",
  SP: "CLASICO",
  SALON: "SALON CAMA",
  "SALON MIXTO": "SALON CAMA",
  SEMICAMA: "SEMI CAMA",
  CAMA: "SALON CAMA",
  COMUN: "CLASICO",
  "COMUN CON AIRE": "CLASICO",
  "Comun con Aire": "CLASICO",
  SCA: "CLASICO",
  Preferente: "PREFERENTE",
  Salon: "SALON CAMA",
  "Salón Sin Vent": "SALON SIN VENT",
  "Salon Sin Vent": "SALON SIN VENT",
  "Cama Premium": "CAMA PREMIUM",
  "CAMA PREMIUM": "CAMA PREMIUM",
  "Bio Bio Superior": "BIO BIO SUPERIOR",
  "SALON CAMA P": "SALON CAMA P",
  "SALON CAMA IND": "SALON CAMA IND"
};

const getSeatType = (type, seatIds) => {
  let types = seatIds;
  return types[type];
};

const ReducerUtilService = {
  setCities: (payload, cities) => {
    console.log("hello", new Date());
    let pairsArr = [];
    if (payload) {
      let keys = payload[0];
      for (let i = 1; i < payload.length; i++) {
        let originObj = {};
        let destObj = {};
        let currentObj = {
          origin: {},
          destination: {},
        };
        originObj = cities.find((city) => {
          return city.id == payload[i][0];
        });
        destObj = cities.find((city) => {
          return city.id == payload[i][1];
        });
        currentObj.origin = originObj;
        currentObj.destination = destObj;
        pairsArr.push(currentObj);
      }
    }
    let originGroups = [];
    let origins = {};
    let destinationsGroup = {};
    let destinationsList = [];
    for (let city of pairsArr) {
      if (city.origin) {
        let currentOrigin = city.origin.id;
        if (typeof origins[currentOrigin] == "undefined") {
          origins[currentOrigin] = currentOrigin;
          originGroups.push(city.origin);
        }
        if (typeof destinationsGroup[currentOrigin] == "undefined") {
          destinationsGroup[currentOrigin] = [];
        }
        if (city.destination) {
          destinationsGroup[currentOrigin].push(city.destination);
          destinationsList.push(city.destination);
        }
      }
    }

    let citiesData = {
      origins: originGroups,
      destinations: destinationsGroup,
      destinationsList: destinationsList,
    };
    console.log("end", new Date());
    return citiesData;
  },

  //format service list reponse

  getFormattedRoutes: (routes) => {
    let returnRoutes = [];
    for (let route of routes) {
      if (route.fare_str) {
        let splitItemArray = route.fare_str.split(",");
        splitItemArray.sort((a, b) => {
          let aValue = a.split(":");
          let bValue = b.split(":");
          if (Number.parseInt(aValue[1]) < Number.parseInt(bValue[1]))
            return -1;
          if (Number.parseInt(aValue[1]) > Number.parseInt(bValue[1])) return 1;
          return 0;
        });
        // console.log("FarePipe  ",splitItemArray);
        if (splitItemArray[0]) {
          route.lowestFarePrice = splitItemArray[0];
        }
        // route.lowestFarePrice = splitItemArray[1];
      }
      route.seatType = [];
      let arr = route.fare_str.split(",");
      for (let s = 0; s <= arr.length; s++) {
        if (arr[s] && arr[s].length > 0) {
          let curr = arr[s].replace(":", ": $");
          route.seatType.push(curr);
        }
      }
      returnRoutes.push(route);
    }

    return returnRoutes;
  },
  makeServiceDetailsApi: (serviceDetails) => {
    let floor = serviceDetails.bus_layout.floor;
    let floorArr = [];
    let lowerFloor = [];
    let upperFloor = [];
    let seat_details = [];

    let available = [];
    let availableFloor = [];
    let availableArr;
    let availableArrFloor = [];
    let oAvailableArrFloor = [];
    let oAvailableFloor = [];
    let availableSeats = 0;
    let oAvailableSeats = 0;

    // if (!CommonService.isTrain()) {
    //   availableArr = serviceDetails.bus_layout.available.split(",");
    //   for (let i = 0; i < availableArr.length; i++) {
    //     let cell = availableArr[i].split("|");
    //     available.push({
    //       seatNumber: cell[0],
    //       seatFare: cell[1],
    //     });
    //     if (cell && cell[0]) {
    //       availableSeats++;
    //     }
    //   }
    // } else {
    availableArrFloor = serviceDetails.bus_layout.available.split(":");
    oAvailableArrFloor =
        serviceDetails.bus_layout.o_availabity && serviceDetails.bus_layout.o_availabity.split(':');

    let j = 0;
    for (let floor of availableArrFloor) {
      let availableArr = floor.split(",");
      availableFloor[j] = [];
      for (let i = 0; i < availableArr.length; i++) {
        let cell = availableArr[i].split("|");
        availableFloor[j].push({
          seatNumber: cell[0],
          seatFare: cell[1],
        });
        if (cell && cell[0]) {
          availableSeats++;
        }
      }
      j++;
    }

    let k = 0;
      for (let floor of oAvailableArrFloor) {
        let oAvailableArr = floor.split(',');
        oAvailableFloor[k] = [];
        for (let i = 0; i < oAvailableArr.length; i++) {
          let cell = oAvailableArr[i].split('|');
          oAvailableFloor[k].push({
            seatNumber: cell[0],
            seatFare: cell[1],
          });
          if (cell && cell[0]) {
            oAvailableSeats++;
          }
        }
        k++;
      }
    // }
    console.log("availableArrFloor", availableArrFloor, availableFloor);
    let coach_details = [];
    // if (!CommonService.isTrain()) {
    //   let rowArr = serviceDetails.bus_layout.coach_details.split(',');
    //   for(let i=0;i<rowArr.length;i++){
    //       let colArr = rowArr[i].split('-');
    //       for(let j=0;j<colArr.length;j++){
    //           let cell =  colArr[j].split('|');
    //           coach_details.push({
    //               seatNumber:cell[0],
    //               seatType:cell[1]?cell[1]:'',
    //               rowId:i,
    //               colId:j
    //           })
    //       }
    //   }
    // } else {

    let indexOfColon = serviceDetails.bus_layout.coach_details.indexOf(":");
    let coachFloors = serviceDetails.bus_layout.coach_details.split(":");
    let floorNo = 0;
    for (let floor of coachFloors) {
      let rowArr = floor.split(",");
      for (let i = 0; i < rowArr.length; i++) {
        let colArr = rowArr[i].split("-");
        for (let j = 0; j < colArr.length; j++) {
          let cell = colArr[j].split("|");
          // debugger
          coach_details.push({
            seatNumber: cell[0],
            seatType: cell[1] ? cell[1] : "",
            rowId: i,
            colId: j,
            floorNo: floorNo,
          });
        }
      }
      floorNo++;
      // }
    }
    console.log("coach_details", coach_details);
    let floors = [];
    if (floor) {
      // if (!CommonService.isTrain()) {
      //   if (floor.indexOf("@") >= 0) {
      //     floorArr = floor.split("@");
      //     if (floorArr[1] && floorArr[1].indexOf(",") > -1) {
      //       lowerFloor = floorArr[1].split(",");
      //       upperFloor = floorArr[0].split(",");
      //     } else {
      //       floorArr = floor.split("@");
      //       lowerFloor = floorArr[0].split(",");
      //     }
      //   } else {
      //     lowerFloor = floor.split(",");
      //   }
      // } else {
      if (floor.indexOf("@") >= 0) {
        floorArr = floor.split("@");
        lowerFloor = floorArr[0] ? floorArr[0].split(",") : [];
        if (floorArr[1] && floorArr[1].indexOf(",") > -1) {
          upperFloor = floorArr[1].split(",");
        }
        let i = 0;
        for (let arr of floorArr) {
          let seatReverse = arr ? arr.split(":") : [];
          floors[i] = {};
          if (seatReverse[0] && seatReverse[0].indexOf(",") > -1) {
            floors[i].normal = seatReverse[0].split(",");
          }
          if (seatReverse[1] && seatReverse[1].indexOf(",") > -1) {
            floors[i].reverse = seatReverse[1].split(",");
          }
          i++;
        }
      } else {
        lowerFloor = floor.split(",");
      }
      // }
    }
    console.log("floors --------", floors);
    let seatAvailable;
    let noAvailableColon = serviceDetails.bus_layout.available.indexOf(":") < 0;
    let availableArrNoCol = serviceDetails.bus_layout.available.split(",");

    let oNoAvailableColon = serviceDetails.bus_layout.o_availabity.indexOf(':') < 0;
    let oAvailableArrNoCol = serviceDetails.bus_layout.o_availabity.split(',');
    for (let i = 0; i < coach_details.length; i++) {
      let seatJson = {};
      let isSeat = false;
      let seatType = getSeatType(coach_details[i].seatType, seatIdentifiers);
      let isGangway =
        coach_details[i].seatType.indexOf("GY") >= 0 || !seatType
          ? true
          : false;
      let berthLower;
      let berthUpper;
      let berthType;
      let seatDirection;
      // if (!CommonService.isTrain()) {
      //   berthLower = lowerFloor.find((number) => {
      //     if (number == coach_details[i].seatNumber) {
      //       return true;
      //     }
      //   });
      //   if (upperFloor) {
      //     berthUpper = upperFloor.find((number) => {
      //       if (number == coach_details[i].seatNumber) {
      //         return true;
      //       }
      //     });
      //   }
      // } else {
      let floorno = 0;
      for (let floor of floors) {
        if (floorno === coach_details[i].floorNo) {
          let isSeatInFloorNoraml = null;
          let isSeatInFloorRev = null;
          seatDirection = null;
          isSeatInFloorNoraml =
            floor.normal &&
            floor.normal.find((number) => {
              if (number == coach_details[i].seatNumber) {
                return true;
              }
            });
          if (floor.reverse) {
            isSeatInFloorRev = floor.reverse.find((number) => {
              if (number == coach_details[i].seatNumber) {
                return true;
              }
            });
          }
          // console.log("isSeatInFloorNoraml", isSeatInFloorNoraml);
          if (isSeatInFloorNoraml) {
            berthType = floorno;
            seatDirection = 1;
            break;
          }
          if (isSeatInFloorRev) {
            berthType = floorno;
            seatDirection = 2;
            break;
          }
        }
        floorno++;
      }
      // }
      let seatAvailable;
      let oSeatAvailable;
      if (
        !(CommonService.isTrain() || serviceDetails.travel_id == 272) &&
        noAvailableColon
      ) {
        let availableItem = availableArrNoCol.find((seat) => {
          return seat.split("|")[0] == coach_details[i].seatNumber;
        });
        if (availableItem) {
          seatAvailable = {
            seatNumber: availableItem.split("|")[0],
            seatFare: availableItem.split("|")[1],
          };
        }

        let oAvailableItem = oAvailableArrNoCol.find(seat => {
            return seat.split('|')[0] == coach_details[i].seatNumber;
          });
          if (oAvailableItem) {
            oSeatAvailable = {
              seatNumber: oAvailableItem.split('|')[0],
              seatFare: oAvailableItem.split('|')[1],
            };
          }
      } else {
        if (availableFloor[coach_details[i].floorNo]) {
          seatAvailable = availableFloor[coach_details[i].floorNo].find(
            (seat) => {
              return seat.seatNumber == coach_details[i].seatNumber;
            }
          );
        }
      }
      if (indexOfColon < 0) {
        berthLower = lowerFloor.find((number) => {
          if (number == coach_details[i].seatNumber) {
            return true;
          }
        });
        if (upperFloor) {
          berthUpper = upperFloor.find((number) => {
            if (number == coach_details[i].seatNumber) {
              return true;
            }
          });
        }
      }

      let fare = seatAvailable ? seatAvailable.seatFare : null;
      let oFare = oSeatAvailable ? oSeatAvailable.seatFare : null;
      seatJson["available"] = seatAvailable ? true : false;
      seatJson["fare"] = fare;
      seatJson['o_fare'] = oFare;
      seatJson["is_ladies_seat"] = false;
      seatJson["is_gents_seat"] = false;
      seatJson["type"] = seatType ? seatType : "SEMI CAMA";
      seatJson["seat_direction"] = seatDirection;
      seatJson["floor_no"] =
        indexOfColon >= 0
          ? coach_details[i].floorNo
          : berthLower
          ? 0
          : berthUpper
          ? 1
          : floor
          ? ""
          : 0;
      // seatJson["berthType"] = CommonService.isTrain()
      //   ? berthType
      //   : berthLower
      //   ? "lower"
      //   : berthUpper
      //   ? "upper"
      //   : "";
      seatJson["berthType"] = berthType;
      seatJson["number"] = coach_details[i].seatNumber;
      seatJson["row_id"] = coach_details[i].rowId;
      seatJson["col_id"] = coach_details[i].colId;
      seatJson["is_seat"] = true;
      seatJson["is_gangway"] = isGangway;
      seatJson["is_horizontal"] = false;
      if (!isGangway) {
        seatJson["discount_amount"] = 0;
        seatJson["net_amount"] = fare - seatJson["discount_amount"];
      }
      seat_details.push(seatJson);
    }

    let stages = [];
    let coachDetails = {
      total_seats: coach_details.length,
      available_seats: available.length,
      driver_position: "left",
      seat_details: seat_details,
    };
    let obj = {
      origin: { id: +serviceDetails.origin_id },
      destination: { id: +serviceDetails.destination_id },
      available_seats: serviceDetails.available_seats,
      total_available_seats: availableSeats,
      dep_time: serviceDetails.dep_time,
      arr_time: serviceDetails.arr_time,
      duration: serviceDetails.duration,
      bus_type: serviceDetails.bus_type,
      no_of_wagons: floors.length,
      cost: serviceDetails.cost,
      coach_details: coachDetails,
      stage_details: stages,
      boarding_stages: serviceDetails.bus_layout.boarding_stages,
      dropoff_stages: serviceDetails.bus_layout.dropoff_stages,
      travel_id: serviceDetails.travel_id,
      res_details: serviceDetails.res_details,
    };
    let retObj = JSON.parse(JSON.stringify(obj));
    console.log(obj);
    obj = null;
    return retObj;
  },
};

export default ReducerUtilService;
